<template>
    <div class="mt-5" id="niQup">
    <section id="about" class="about pt-5">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2 style="color:green;">Privacy Policy</h2>
        </div>

        <div class="row">
          <div class="col-lg-8">
        <ul class="p-3">
            <li>
                We use your personal information to provide the product & services you request.
            </li>
            <li>With your consent, We will access to your SMS, location, device information & camera.</li>
            
            <li>
                User data is compeletely secure on QuPro application & web.
            </li>
            <li>
              Shipping time: Minimum 1 hour for nearby shop products & Maximum 15 days. 
            </li>
            <li>
              There is no refundable policy.
            </li>
            <li>
                Users will get new features on every update.
            </li>
            <li>
                We may also request you to  provide your PAN, GST Number, Govt. issued ID cards/ Number & KYC details to check your eligibility for certain products & services.
            </li>
            <li>
                We identify & use your IP address to help diagnose problems with our server.
            </li>
            <li>
                We will occassionally ask you to participate in optional surveys conducted either by us or through a third party market research agency.
            </li>
            <li>
                We use data collection device such as cookies on certain pages of the platform to help analysis our web page flow, major promotional effectiveness & promote trust and safety.
            </li>
            <li>
                Our platform may provide links to other websites & applications that may collect personal information about you. 
                We are not responsible for the privacy practices or the content of those linked websites.
            </li>
            <li>
                Users are responsible for ensuring the protection of login and password record for their account.
            </li>
            <li>
                Please accept products from our authorized delivery persons.
            </li>
            <li>
                We may retain data related to you if we believe it may be necessary to prevent fraud or future abuse or if required by law or for other legitimate purpose.
            </li>
            <li>
                Please check our privacy policy periodically for changes.
            </li>
            <b>
                Contact us for your queries 24x7.
            </b>
            </ul>

            
            <hr>
           
            
          </div>
          
        </div>

      </div>
    </section>
    
    </div>
</template>
<script>
export default {
    name: 'policy',
    
}
</script>

<style scoped>

.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-left: 28px;
  position: relative;
}

.about .content ul li + li {
  margin-top: 10px;
}

.about .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #47b2e4;
  line-height: 1;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .content .btn-learn-more {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 4px;
  transition: 0.3s;
  line-height: 1;
  color: #47b2e4;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  margin-top: 6px;
  border: 2px solid #47b2e4;
}

.about .content .btn-learn-more:hover {
  background: #47b2e4;
  color: #fff;
  text-decoration: none;
}

#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us .content {
  padding: 60px 100px 0 100px;
}

.why-us .content h3 {
  font-weight: 400;
  font-size: 34px;
  color: #313233;
}

.why-us .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}

.why-us .content p {
  font-size: 15px;
  color: #848484;
}

.why-us .img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.why-us .accordion-list {
  padding: 0 100px 60px 100px;
}

.why-us .accordion-list ul {
  padding: 0;
  list-style: none;
}

.why-us .accordion-list li + li {
  margin-top: 15px;
}

.why-us .accordion-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

.why-us .accordion-list a {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-right: 30px;
  outline: none;
}

.why-us .accordion-list span {
  color: #47b2e4;
  font-weight: 600;
  font-size: 18px;
  padding-right: 10px;
}

.why-us .accordion-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.why-us .accordion-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.why-us .accordion-list .icon-show {
  display: none;
}

.why-us .accordion-list a.collapsed {
  color: #343a40;
}

.why-us .accordion-list a.collapsed:hover {
  color: #47b2e4;
}

.why-us .accordion-list a.collapsed .icon-show {
  display: inline-block;
}

.why-us .accordion-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1024px) {
  .why-us .content, .why-us .accordion-list {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .why-us .img {
    min-height: 400px;
  }
  .why-us .content {
    padding-top: 30px;
  }
  .why-us .accordion-list {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .why-us .img {
    min-height: 200px;
  }
}

</style>