<template>
    <div >
        <div class="mt-5" style="text-align: center;">
            <Carousel style="z-index: 5;"/>
        </div>
        <div>
                <vueper-slides
                    
                    :visible-slides="10"
                    slide-multiple
                    :gap="1"
                    :slide-ratio="rto"
                    :infinite="false"
                    :arrows= false
                    style="z-index: 333; background: white;"
                    :dragging-distance="100"
                    :bullets= "true"
                    :breakpoints="{ 800: { visibleSlides: 3, slideMultiple: 3 } }">
                    
                    <vueper-slide 
                        v-for="i in data" :key="i" 
                        :title="i"
                        @click.native="goToPage(i)"
                        class="shadow">
                        <router-link />
                    </vueper-slide>
                    
                </vueper-slides>
                
            </div>
            <div class="">
                <div class="col-lg-11">
                    <Paginate style=" margin: 1rem;"/>
                </div>
                
            </div>
            <!-- <Latest />
            <Trending/>
            <Mobiles/> -->

            
            <!-- <Clothes/> -->
            <div class="" >
                    <img 
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQdAcoEEnrxySkqywQ7cQeg4dSw54PzXCRNjA&usqp=CAU" 
                    alt=""
                    class="advert">
                </div>
            
            
            

            

            <!-- <Books/>
            <Cosmetics/>
            <Electronics/>
            <Jewells/>
            <Shoes/>
            <Sports/>
            <BagBottles/>
            <Grocery/>
            <HomeUtils/>
            <Kitchen/>
            <ToysGifts/>
            <Utencils/>
            <Veg/>
            <AutoMob/>
            <Furniture/>
            <Construction/> -->
        <!-- </div> -->
    </div>
</template>

<script>
    import Carousel from "../components/Carousel.vue";
    import { VueperSlides, VueperSlide } from 'vueperslides';
    import 'vueperslides/dist/vueperslides.css';
    // import QuProducts from "../components/productPages/others/quProducts.vue";
    import Paginate from "../components/core/cardPaginate.vue";
    // import Clothes from '../components/productPages/categories/clothes.vue'

    
    import $ from 'jquery';

    export default {
        name: "Home",
        components: {
            VueperSlides, VueperSlide, 
            // QuProducts,
             Carousel, Paginate, 
            //  Clothes
        },
        data() {
            return {
                fullData: [],
                rto: 1/25,
                data: ['Vegetables', 'Mobiles', 'Grocery', 
                    'Shoes', 'Clothes', 'Gifts', 'Books', 'Electronics', 
                    'Jewellery', 'Cosmetics', 'Utencils', 'Sports', 
                    'Construction', 'Home', 'bag & Bottle', 'AutoMobiles', 
                    'Food', 'Kitchen']
            }
        },
        mounted(){
            if($(window).width()<=1199){
                this.rto = 1/8;
            }else{
                this.rto = 1/25;
            }
        },
        methods:{
            goToPage(catData){
                this.$router.push('/seeAll/'+catData)
            }
        }
    }
</script>

<style scoped>

#wpf-loader-two .wpf-loader-two-inner:before {
  background: #c762cb;  
}
#wpf-loader-two .wpf-loader-two-inner:after {
  background: #c762cb;  
}
#wpf-loader-two {
  background-color: #c762cb;  
}

.advert{
  height: 100%; 
  width: 200px; 
  position: fixed; 
  z-index: 1;
  top: 0; 
  right: 0;
  overflow-x: hidden;
  padding-top: 70px; 
}

@media screen and (max-width: 1199px) {
    .advert{
        display: none;
    }
}
</style>
