<template>
    <Fragment>
    <section id="aa-contact">
   <div class="container">
     <div class="row">
       <div class="col-md-12">
         <div class="aa-contact-area">
           <div class="aa-contact-top">
             <h2>We are wating to assist you..</h2>
             <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi, quos.</p>
           </div>
           <!-- contact map -->
           <div class="aa-contact-map">
             <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14146.564985452254!2d76.6151314!3d27.5736424!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x24cbcf42762fc7e5!2sQuPro%20Solutions!5e0!3m2!1sen!2sin!4v1603443227840!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
            <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14146.565526974062!2d76.6151263!3d27.5736382!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2cc16e8abf9c183a!2sTimes%20Tutorials!5e0!3m2!1sen!2sin!4v1602107450946!5m2!1sen!2sin" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> -->
              <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14146.565526974062!2d76.6151263!3d27.5736382!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2cc16e8abf9c183a!2sTimes%20Tutorials!5e0!3m2!1sen!2sin!4v1602107450946!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style="border:0" allowfullscreen></iframe> -->
           </div>
           <!-- Contact address -->
           <div class="aa-contact-address">
             <div class="row">
               <div class="col-md-8">
                 <div class="aa-contact-address-left">
                   <form class="comments-form contact-form" action="">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">                        
                          <input type="text" placeholder="Your Name" class="form-control">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">                        
                          <input type="email" placeholder="Email" class="form-control">
                        </div>
                      </div>
                    </div>
                     <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">                        
                          <input type="text" placeholder="Subject" class="form-control">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">                        
                          <input type="text" placeholder="Company" class="form-control">
                        </div>
                      </div>
                    </div>                  
                     
                    <div class="form-group">                        
                      <textarea class="form-control" rows="3" placeholder="Message"></textarea>
                    </div>
                    <button class="btn btn-secondary">Send</button>
                  </form>
                 </div>
               </div>
               <div class="col-md-4">
                 <div class="aa-contact-address-right">
                   <address>
                     <h4>QuPro Solutions</h4>
                     <!-- <p>QuPro Solutions</p> -->
                     <p><span class="fa fa-home"></span>15, Basant Vihar Scheme 3, Alwar(Raj)</p>
                     <p><span class="fa fa-phone"></span>(+91) 9653940783</p>
                     <p><span class="fa fa-envelope"></span>Email: Quprosolutions@gmail.com</p>
                   </address>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </section>
 </Fragment>
</template>
<script>
    export default {
        name: "Header",
    }
</script>
<style scoped>aa-contact
#aa-contact {
  background: #ccc 0 0;
  display: inline;
  float: left;
  width: 100%;
}
#aa-contact .aa-contact-area {
  display: inline;
  float: left;
  padding: 50px 0;
  width: 100%;
}
#aa-contact .aa-contact-area .aa-contact-top {
  background-color: #FFF;
  display: inline;
  float: left;
  padding: 50px 100px;
  text-align: center;
  width: 100%;
}
#aa-contact .aa-contact-area .aa-contact-top h2 {
  color: #555;
  padding-bottom: 0px;
}
#aa-contact .aa-contact-area .aa-contact-top p {
  color: #555;
}
#aa-contact .aa-contact-area .aa-contact-map {
  display: inline;
  float: left;
  width: 100%;
}
#aa-contact .aa-contact-area .aa-contact-map iframe {
  margin-bottom: -6px;
}
#aa-contact .aa-contact-area .aa-contact-address {
  background-color: #FFF;
  display: inline;
  float: left;
  padding: 50px;
  width: 100%;
}
#aa-contact .aa-contact-area .aa-contact-address .aa-contact-address-left {
  display: inline;
  float: left;
  width: 100%;
}
#aa-contact .aa-contact-area .aa-contact-address .aa-contact-address-left .comments-form {
  margin-top: 10px;
}
#aa-contact .aa-contact-area .aa-contact-address .aa-contact-address-left .comments-form input {
  height: 40px;
  font-size: 16px;
  border-radius: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
#aa-contact .aa-contact-area .aa-contact-address .aa-contact-address-left .comments-form textarea {
  border-radius: 0;
  font-size: 16px;
  height: 200px;
  padding: 10px;
  width: 70%;
}
#aa-contact .aa-contact-area .aa-contact-address .aa-contact-address-left .comments-form .form-control:focus {
  box-shadow: none;
}
#aa-contact .aa-contact-area .aa-contact-address .aa-contact-address-left .comments-form .form-group {
  margin-bottom: 20px;
  float: left;
  width: 100% !important;
}
#aa-contact .aa-contact-area .aa-contact-address .aa-contact-address-left .comments-form button { 
  width: 100px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
#aa-contact .aa-contact-area .aa-contact-address .aa-contact-address-right {
  display: inline;
  float: left;
  width: 100%;
}
#aa-contact .aa-contact-area .aa-contact-address .aa-contact-address-right address h4 {
  font-size: 22px;
}
#aa-contact .aa-contact-area .aa-contact-address .aa-contact-address-right address span {
  margin-right: 10px;
}
</style>