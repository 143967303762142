<template>
  <aside class="mt-5 pt-5 row">
    <div class="col-lg-1" />
    <div class="shadow col-lg-3">
      <!-- <p class="pt-5">Nishant</p> -->
      <img :src="product.images" alt="ProductImg" class="col-sm-12" />
    </div>
    <article class="p-5 shadow col-lg-7">
      <h3 class="title mb-3">{{ product.title }}</h3>
      <p class="price-detail-wrap">
        <span class="price h3 text-warning">
          <span class="currency">₹</span
          ><span class="num">{{ product.price }}</span>
        </span>
      </p>
      <dl class="item-property">
        <dt>Description</dt>
        <dd>
          <p class="text-capitalize">{{ product.description }}</p>
        </dd>
      </dl>

      <dl class="param param-feature" v-if="product.brand">
        <dt>Brand</dt>
        <dd class="text-capitalize">{{ product.brand }}</dd>
      </dl>
      <dl class="param param-feature" v-if="product.size">
        <dt>Size</dt>
        <dd>{{ product.size }}</dd>
      </dl>
      <dl class="param param-feature" v-if="product.camera">
        <dt>Camera</dt>
        <dd>{{ product.camera }}</dd>
      </dl>
      <dl class="param param-feature" v-if="product.cpu">
        <dt>CPU</dt>
        <dd>{{ product.cpu }}</dd>
      </dl>
      <dl class="param param-feature" v-if="product.ram">
        <dt>Memory</dt>
        <dd>{{ product.memory }}</dd>
      </dl>
      <dl class="param param-feature" v-if="product.display">
        <dt>Display</dt>
        <dd>{{ product.display }}</dd>
      </dl>
      <dl class="param param-feature" v-if="product.battery">
        <dt>Battery</dt>
        <dd>{{ product.battery }}</dd>
      </dl>
      <hr />
      <dl class="param param-feature" v-if="product.price < 500">
        <dt>Delivery charges apply</dt>
        <dd>₹ 20</dd>
      </dl>
      <dl class="param param-feature">
        <dd>+ 2% transaction charge</dd>
      </dl>
      <hr />
      <div v-if="product.qty != 0 && product.qty != ''" class="col">
        <div class="row">
          <button
            @click="onAddProductToCart()"
            class="btn btn-outline-success text-uppercase ml-2"
          >
            <i class="fa fa-shopping-cart"></i> Add to cart
          </button>
        </div>
        <div class="">
          <p>Select payment option:</p>
          <button
            class="btn btn-outline-primary text-uppercase col-lg-3"
            @click="buyNow(product.price, proId, product)"
          >
            <i class="fa fa-money"></i> Pay Now
          </button>
          <!-- <button class="btn btn-outline-success col-lg-3">
            <a @click="waMsg()"><i class="fa fa-whatsapp"></i> WhatsApp</a>
          </button> -->
          <button
            class="btn btn-outline-secondary text-uppercase col-lg-3"
            @click="cashOn(product.price, proId, product)"
          >
            <i class="fa fa-money"></i> Cash On Delivery
          </button>
        </div>
      </div>
      <div v-else>
        <p>Currently out of stock</p>
      </div>
    </article>
  </aside>
</template>

<script>
import { ADD_PRODUCT_TO_CART } from "../../store/types";
import firebase from "firebase";

export default {
  name: "ProductDetail",
  data() {
    return {
      // sharePath: '',
      product: [],
      proId: "",
      proCat: "",
    };
  },
  // props: {
  //     product: Object
  // },
  mounted() {
    // this.product = this.$store.state.productOne;
    this.loadProduct();
    // console.log('-'+this.proId);
    // console.log(this.proCat);
  },
  methods: {
    loadProduct() {
      var path = location.pathname;
      var directories = path.split("/");
      this.proId = directories[directories.length - 1];
      this.proCat = directories[directories.length - 2];

      var booksData = firebase.database().ref("shoProducts").child(this.proCat).child(this.proId);
      // .child(this.proId);
      // //console.log(booksData)
      booksData.on("value", (snapshot) => {
        // var allBookObj = snapshot.val();
        // console.log(allBookObj)
        this.product = snapshot.val();
        // console.log(this.product)

        // this.productKeys = Object.keys(this.fullData)
      });
    },

    onAddProductToCart() {
      this.$swal({
        title: this.product.title + " added to cart!",
        icon: "success",
        button: "Done!",
      });
      this.$store.commit(ADD_PRODUCT_TO_CART, [this.product, this.proId]);
    },
    waMsg() {
      location.href =
        "https://api.whatsapp.com/send?phone=+919660663783&text=" +
        window.location.pathname;
    },

    buyNow(rupee, prodId, product) {
      var token = localStorage.getItem("userData");
      if (token != "" && token != null && token != undefined) {
        this.buyN(rupee, prodId, product);
      } else {
        alert("You have to login first.");
      }
    },

    buyN: function (rupee, prodId, product) {
      var prc;
      if (rupee <= 200 && rupee > 0) {
        prc = 20;
      } else if (rupee > 200) {
        prc = 0;
      }
      var amt = parseInt(rupee) + prc;

      var payable = amt * 100 + ((amt * 100) / 100) * 2;

      var alpha = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "0",
      ];
      var nums = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];

      var one = alpha[Math.floor(Math.random() * alpha.length)];
      var two = alpha[Math.floor(Math.random() * alpha.length)];
      var thr = nums[Math.floor(Math.random() * nums.length)];
      var frt = alpha[Math.floor(Math.random() * alpha.length)];
      var fiv = nums[Math.floor(Math.random() * nums.length)];
      var six = nums[Math.floor(Math.random() * nums.length)];

      var order = one + two + thr + frt + fiv + six;

      let rzp1 = new window.Razorpay({
        key: "rzp_live_l7EWMLcpJPWY3s",
        // key_secret: 'EsYn4FdxR2ZcKtmGPzwCwdgi',
        amount: payable,
        name: "QuPro Solutions",
        currency: "INR",
        description: "Check Out for your Product.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/qupro-solutions.appspot.com/o/logo.png?alt=media&token=f907f861-e243-4161-836b-030c58049c49",
        //Uncomment if you are using handler function
        handler: function (response) {
          // razorOrderId = response.razorpay_order_id
          // razorPaymentId = response.razorpay_payment_id
          // razorSignature = response.razorpay_signature
          // alert(response.razorpay_payment_id);

          var token = localStorage.getItem("userData");
          firebase
            .firestore()
            .collection("users")
            .doc(token)
            .collection("orders")
            .doc(prodId)
            .set({
              orderId: order,
              paymentId: response.razorpay_payment_id,
              // 'signature': response.razorpay_signature
            }); //set/copy the data to new path.
          firebase
            .database()
            .ref("/shopData")
            .child(product.shopId)
            .child("Orders")
            .push()
            // firebase.firestore().collection("shop").doc().collection("orders").doc(prod[i].proKey)
            .set({
              orderId: order,
              productId: prodId,
              timeToOrd: new Date().toLocaleString(),
              customer: token,
              paymentId: response.razorpay_payment_id,
              // 'signature': response.razorpay_signature,
              qty: "1",
            });
        },
        // callback_url: 'http://13.126.183.214/razorpay/checkstatus/?orderid='+this.order_id,
        // prefill: {
        // name: "Nishant Sharma",
        // email: "rjnishant49@gmail.com",
        // },
        notes: {
          address: "Scheme 3, Alwar",
        },
        theme: {
          color: "#00ffff",
        },
        // order_id: shopId,
        // callback_url: "http://localhost:8081/",
        // redirect: true
      });

      rzp1.open();
    },

    cashOn(rupee, prodId, product) {
      var prc;
      if (rupee <= 200 && rupee > 0) {
        prc = 20;
      } else if (rupee > 200) {
        prc = 0;
      }
      var amt = parseInt(rupee) + prc;
      // console.log(amt);
      // console.log(prodId);
      // console.log(product);
      // console.log(order);

      var alpha = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "0",
      ];
      var nums = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];

      var one = alpha[Math.floor(Math.random() * alpha.length)];
      var two = alpha[Math.floor(Math.random() * alpha.length)];
      var thr = nums[Math.floor(Math.random() * nums.length)];
      var frt = alpha[Math.floor(Math.random() * alpha.length)];
      var fiv = nums[Math.floor(Math.random() * nums.length)];
      var six = nums[Math.floor(Math.random() * nums.length)];

      var order = one + two + thr + frt + fiv + six;

      var token = localStorage.getItem("userData");
      firebase
        .firestore()
        .collection("users")
        .doc(token)
        .collection("orders")
        .doc(prodId)
        .set({
          orderId: order,
          due: amt,
          // 'signature': response.razorpay_signature
        }); //set/copy the data to new path.
      firebase
        .database()
        .ref("/shopData")
        .child(product.shopId)
        .child("Orders")
        .push()
        // firebase.firestore().collection("shop").doc().collection("orders").doc(prod[i].proKey)
        .set({
          orderId: order,
          productId: prodId,
          timeToOrd: new Date().toLocaleString(),
          customer: token,
          due: amt,
          // 'signature': response.razorpay_signature,
          qty: "1",
        })
        .then(
          this.$swal({
            title: this.product.title + "\n Order placed!",
            icon: "success",
            button: "Done!",
          })
        );
    },
  },
};
</script>

<style scoped>
</style>
