<template>
  <div class="pb-5 pt-5">
    <div class="">
      <div class="row">
        <div>
          <div class="row pt-3">
            <div
              class="col-lg-3 ml-5 mt-2"
              v-for ="productKey in productKeys" :key="productKey.id"
            >
              <a
                data-toggle="tooltip"
                class="tTip"
                :title="fullData[productKey].description"
              >
                <div
                  class="card position-relative shadow-lg bg-white programCard"
                >
                  <div class="cardImage" style="text-align: center">
                    <img
                      class="card-img-top"
                      v-if="fullData[productKey].images"
                      :src="fullData[productKey].images"
                      alt="Card Image"
                    />
                    <img
                      class="card-img-top"
                      v-else-if="!fullData[productKey].images"
                      src="https://i.stack.imgur.com/jRLAt.png"
                      alt="Card Image"
                    />
                    
                    <div class="rwGvJ" v-if="fullData[productKey].price">
                      <div class="hICYdA"></div>
                      <div class="BatchCard__BatchTag-sc-1iui1jg-4 jigtzU">
                        <span
                          class="BatchCard__BatchTextTag-sc-1iui1jg-5 bcilPk"
                          >10% OFF</span
                        >
                      </div>
                    </div>
                    
                  </div>

                  <div class="card-body">
                    <h5 class="row card-text pl-3 pr-3 text-secondary">
                      <b>{{ fullData[productKey].title }}</b>
                    </h5>
                    <p class="row card-text pl-3 pr-3 text-secondary">
                      {{ fullData[productKey].description }}
                    </p>
                    <p class="row card-text pl-3 pr-3 text-secondary">
                      <small style="color: rgb(199, 112, 112); font-size: 20px;"> ₹{{ fullData[productKey].price }} </small>
                    </p>

                    <hr />
                    <div class="float-right pt-1 pb-2">
                      <span type="hollow" 
                      class="dHfCyZ"
                      v-on:click="onAddProductToCart1(fullData[productKey], productKey)"
                      >
                        <i class="fa fa-shopping-cart"></i> Add to Cart</span
                        >
                      <span
                        type="hollow"
                        class="dHfCyZ1 ml-1"
                        v-on:click="buyNow(fullData[productKey].price, productKey)"
                        >Buy Now</span
                      >
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Types from "../../store/types";
import firebase from 'firebase';
// import Razorpay from 'razorpay';
// import {bus} from '../../main.js';
export default {
  data() {
    return {
      fullData: [],
      productKeys: [],
      proCat: ''
    };
  },
  mounted() {
      this.loadProduct();
    // this.fullData = this.$store.state.dataVar;
    // this.productKeys = Object.keys(this.fullData)

    // bus.$on('fullData',(val)=>{
    //   //console.log("nk")
    //   // //console.log(val)
    //   this.fullData = val;
    // })
  },
  methods: {

      loadProduct() {
      var path = location.pathname;
      var directories = path.split("/");
      this.proCat = directories[directories.length - 1];

      var booksData = firebase
        .database()
        .ref("/Products")
        .child(this.proCat)
      // //console.log(booksData)
      booksData.on("value", (snapshot) => {
        var allBookObj = snapshot.val();
        // console.log(allBookObj)
        this.fullData = allBookObj;

        this.productKeys = Object.keys(this.fullData)
        // console.log(this.product)

        // this.productKeys = Object.keys(this.fullData)
      });
    },

    buyNow(rupee, prodId){
            var token = localStorage.getItem("userData");
            if(token != '' && token != null && token != undefined){
                this.buyN(rupee, prodId);
            }else{
                alert('You have to login first.')
            }
        },

      buyN: function (rupee, prodId) {
      var payable = rupee * 100 + ((rupee * 100) / 100) * 2;
      
      var alpha = [
          "A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z","1","2","3","4","5","6","7","8","9","0"
      ];
      var nums = ['1','2','3','4','5','6','7','8','9','0'];

      var one = alpha[Math.floor(Math.random() * alpha.length)];
      var two = alpha[Math.floor(Math.random() * alpha.length)];
      var thr = nums[Math.floor(Math.random() * nums.length)];
      var frt = alpha[Math.floor(Math.random() * alpha.length)];
      var fiv = nums[Math.floor(Math.random() * nums.length)];
      var six = nums[Math.floor(Math.random() * nums.length)];

      var order = one+two+thr+frt+fiv+six;

      let rzp1 = new window.Razorpay({
        key: "rzp_live_l7EWMLcpJPWY3s",
        // key_secret: 'EsYn4FdxR2ZcKtmGPzwCwdgi',
        amount: payable,
        name: "QuPro Solutions",
        currency: "INR",
        description: "Check Out for your Product.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/qupro-solutions.appspot.com/o/logo.png?alt=media&token=f907f861-e243-4161-836b-030c58049c49",
        //Uncomment if you are using handler function
        handler: function (response) {
          // razorOrderId = response.razorpay_order_id
          // razorPaymentId = response.razorpay_payment_id
          // razorSignature = response.razorpay_signature
          // alert(response.razorpay_payment_id);

          var token = localStorage.getItem("userData");
          firebase.firestore().collection("users").doc(token).collection("orders").doc(prodId)
           .set({
              'orderId': order,
              'paymentId': response.razorpay_payment_id,
              // 'signature': response.razorpay_signature
          }); //set/copy the data to new path.
          firebase.database().ref('/shopData').child(prodId.shopId).child('Orders').push()
          // firebase.firestore().collection("shop").doc().collection("orders").doc(prod[i].proKey)
          .set({
              'orderId': order,
              'productId': prodId,
              'timeToOrd': new Date().toLocaleString(),
              'paymentId': response.razorpay_payment_id,
              // 'signature': response.razorpay_signature,
              'qty': prodId.quantity
          }); 


        },
        // callback_url: 'http://13.126.183.214/razorpay/checkstatus/?orderid='+this.order_id,
        prefill: {
          name: "Nishant Sharma",
          email: "rjnishant49@gmail.com",
        },
        notes: {
          address: "Scheme 3, Alwar",
        },
        theme: {
          color: "#00ffff",
        },
        // order_id: shopId,
        // callback_url: "http://localhost:8081/",
        // redirect: true
      });

      rzp1.open();
    },

    onAddProductToCart1: function(prod, prodId) {
      //console.log(prod)
      this.$swal({
        title: "Added to cart!",
        icon: "success",
        button: "Done!"
      });
      this.$store.commit(Types.ADD_PRODUCT_TO_CART,  [prod, prodId]);
    },

  },
};
</script>

<style scoped>
.form-popup {
  display: none;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  border-radius: 30px;
  /* bottom: 0; */
  top: 15%;
  bottom: 15%;
  left: 5%;
  right: 5%;
  flex-wrap: wrap;
  width: 90%;
  border: 3px solid #f1f1f1;
  z-index: 1;
}
.form-container {
  max-width: 100%;
  padding: 10px;
  background-color: white;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 5px;
  margin-top: 10%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}
.container {
  position: relative;
  text-align: center;
  color: #fff;
  /* width: 100%; */
}

.centered {
  position: absolute;
  font-size: 20px;
  top: 10%;
  color: #fff;
  left: 50%;
  font-family: "Zapfino", "Savoye LET", Times, serif;
  font-style: normal;
  transform: translate(-50%, -50%);
}
.decription {
  padding-top: 55px;
  font-size: 14px;
  /* color: black; */
}

.bedge {
  border-radius: 2px;
  font-size: 65%;
  line-height: 15px;
}

.programCard {
  height: auto;
  width: 320px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card-img-top {
  height: 100%;
  width: 100%;
  opacity: 0.8;
  border-radius: 10px;
}

.decsCard {
  box-shadow: 0px 4px 16px rgba(189, 189, 189, 0.24);
  border-radius: 10px;
  margin-left: 8px;
  justify-content: center;
  position: absolute;
  top: 180px;
  height: auto;
  width: 95%;
}
p {
  margin-bottom: 0rem;
  padding: 0;
  margin: 0;
}

.eCykC {
  opacity: 1;
  display: flex;
  grid-column: span 6 / auto;
  transition: opacity 1000ms ease-in 0s, transform 400ms ease-in 0s !important;
}

.ghWIBu {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(221, 230, 237, 0.4) 0px 16px 32px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  grid-column: span 6 / auto;
  transition: box-shadow 0.2s ease-in-out 0s;
  flex: 1 1 0%;
}
.exwfAE {
  line-height: 150%;
  font-size: 12px;
  font-weight: normal;
}
.fWVYTt {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  text-align: left;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 152px;
  padding: 16px;
}
.iaXrsE {
  width: 272px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0px;
}
.kVtcyG {
  height: 100%;
  width: 100%;
  transform: scale(1.02);
  background-color: rgb(221, 230, 237);
}
.cyQJxg {
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  border-radius: 8px;
}
.kqPmni {
  width: 0px;
  height: 32px;
  background-color: rgb(255, 255, 255);
  margin-right: -7px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  color: rgb(60, 72, 82);
  font-weight: 600;
  line-height: 32px;
  white-space: nowrap;
  padding: 0px;
  transition: width 300ms ease-out 0s, padding 300ms ease-out 0s;
  overflow: hidden;
}
.jpJaLS {
  box-shadow: none;
}
.rwGvJ {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: -6px;
  top: 16px;
  height: 25px;
}
.hICYdA {
  width: 0px;
  height: 0px;
  left: 10px;
  bottom: 38px;
  border-left: 6px solid transparent;
  border-bottom: 3px solid rgb(60, 72, 82);
}
.jigtzU {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 22px;
  background-color: rgb(252, 168, 58);
  border-radius: 0px 4px 4px;
  padding: 0px 12px 0px 16px;
}
.bcilPk {
  font-size: 15px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 100%;
}
.fWVYTt {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  text-align: left;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 152px;
  padding: 16px;
}
.dHfCyZ1 {
  background-color: rgb(73, 168, 240);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  text-transform: uppercase;
  line-height: 1;
  width: fit-content;
  margin-right: 8px;
  padding: 6px;
  border-radius: 4px;
  transition: all 300ms ease 0s;
  cursor: pointer;
}
.dHfCyZ {
  background-color: rgb(6, 201, 104);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  text-transform: uppercase;
  line-height: 1;
  width: fit-content;
  margin-right: 8px;
  padding: 6px;
  border-radius: 4px;
  transition: all 300ms ease 0s;
  cursor: pointer;
}
.dHfCy3 {
  background-color: rgb(250, 151, 59);
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  display: inline-block;
  /* text-transform: uppercase; */
  line-height: 1;
  width: fit-content;
  margin-right: 8px;
  padding: 6px;
  border-radius: 4px;
  transition: all 300ms ease 0s;
  cursor: pointer;
}
.exwfAE {
  line-height: 150%;
  font-size: 12px;
  color: orange;
  font-weight: normal;
}
.dumfX2 {
  font-weight: normal;
  font-size: 10px;
  line-height: 100%;
  color: rgb(128, 128, 128);
  padding-top: 4px;
  padding-bottom: 2px;
  margin: 0px;
}
.dumfXl {
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: rgb(250, 192, 85);
  padding-top: 2px;
  padding-bottom: 4px;
  margin: 0px;
}
</style>