import Vue from 'vue';
import Vuex from 'vuex';
// import {phones} from "../data/phones";
// import {brands} from "../data/brands";
import * as Types from "./types";
import {brandFilter} from "../filters/brandFilter";
import {orderByFilter} from "../filters/orderByFilter";
// import {paginationPipe} from "../filters/paginationFilter";
import * as fb from '../firebase';
import firebase from 'firebase';
import router from '../main.js';
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        products: [],
        // brands: brands,
        cart: [],
        brandFilter : '',
        orderBy: '',
        perPage: 8,
        currentPage: 1,
        pagesToShow: 3,
        userProfile: {},
        dataVar: [],
        productOne: []
    },
    mutations: {
        [Types.ADD_PRODUCT_TO_CART] (state, product) {
            // console.log(product[0])
            // console.log(product[1])
            // console.log("naman")
          const cartItemIndex = state.cart.findIndex(item => item.id === product[0].id);
          var itemPresent ;
          state.cart.forEach(item=>{
              if(item.id == product[0].id){
                  itemPresent  = true
              }
              else{
                  itemPresent = false
              }
          })
          
            // //console.log(cartItemIndex)
          if(!itemPresent) {
            //   console.log(proKey);
              state.cart.push({...product[0], quantity: 1, proKey: product[1]});
          } else {
              if(state.cart[cartItemIndex].quantity === 10) return void  0;
              state.cart[cartItemIndex].quantity++;
          }
        },
        [Types.REMOVE_PRODUCT_FROM_CART] (state, id) {
          const cartItemIndex =   state.cart.findIndex(item => item.id === id);

          state.cart.splice(cartItemIndex, 1);
        },
        [Types.INCREMENT_CART_ITEM_QUANTITY] (state, id) {
            const cartItemIndex =   state.cart.findIndex(item => item.id === id);
            // // window.console.log(state.cart[cartItemIndex]);
            if(state.cart[cartItemIndex].quantity === 10) return void  0;
            state.cart[cartItemIndex].quantity++;

        },
        [Types.DECREMENT_CART_ITEM_QUANTITY] (state, id) {
            const cartItemIndex =   state.cart.findIndex(item => item.id === id);
            if(state.cart[cartItemIndex].quantity === 1) return void  0;
            state.cart[cartItemIndex].quantity--;
        },
        [Types.PREV_PAGE] (state) {
            state.currentPage--;
        },
        [Types.NEXT_PAGE] (state) {
            state.currentPage++;
        },
        [Types.GO_PAGE] (state, payload) {
            state.currentPage = payload;
        },
        [Types.ORDER_BY_ASC] (state) {
            state.orderBy = 'asc'
        },
        [Types.ORDER_BY_DESC] (state) {
            state.orderBy = 'desc';
        },
        [Types.CLEAR_ORDER_BY_PRICE] (state) {
            state.orderBy = '';
        },
        [Types.ADD_BRAND_TO_FILTER] (state, brand) {
            if(state.brandFilter.includes(brand)) return void 0;

            state.brandFilter += brand;
        },
        [Types.REMOVE_BRAND_FROM_FILTER] (state, brand) {
            const reg = new RegExp(brand, 'gi');
            state.brandFilter = state.brandFilter.replace(reg, '');
        },
        [Types.CLEAR_BRAND_FILTER] (state) {
            state.brandFilter = '';
        },
          setUserProfile(state, val) {
            state.userProfile = val
            
          },
          setData(state, val) {
            state.products = val
            
          },
        //   setPerformingRequest(state, val) {
        //     state.performingRequest = val
        //   },
        //   setPosts(state, val) {
        //     state.posts = val
        //   }
    },
    getters: {
        filterProducts(state) {
            const brands = state.brandFilter;
            const orderBy = state.orderBy;

            const filterByBrandArr = brandFilter(state.products, brands);
            const filterByOrderArr = orderByFilter(filterByBrandArr, orderBy);

            return filterByOrderArr;
        },
        // paginate(state, getters) {
        //     return paginationPipe(getters.filterProducts, {perPage:state.perPage, currentPage: state.currentPage});
        // },
        totalItemCountAfterFilter(state, getters) {
            return getters.filterProducts.length;
        },
        brandsCount(state) {
            const counts = {};
            state.products.forEach(p => {
                counts[p.brand] = counts[p.brand] + 1 || 1;
            });

            return counts;
        },
        cartLength(state) {
            return state.cart.length;
        },
        totalPrice(state) {
            return state.cart.reduce((count, curItem) => {
                return count + (curItem.quantity * curItem.price);
            }, 0);
        }
    },
    actions: {
        async login({ dispatch }, form) {
          // sign user in
          const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password)
          .catch((err) => {
                alert(err)
            })
    
          // fetch user profile and set in state
          
          dispatch('fetchUserProfile')
          localStorage.setItem('userData', user.uid)
        //   this.$router.push('/')
        location.reload();
        },
        async signup({ dispatch }, form) {
          // sign user up
          const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)
          .catch((err) => {
              alert(err)
          })
    
          // create user object in userCollections
          await fb.usersCollection.doc(user.uid).set({
            name: form.name,
            title: form.title,
            addr: form.addr,
            pin: form.pin,
            phn: form.phn,
            city: 'Alwar'
          })
    
          // fetch user profile and set in state
          dispatch('fetchUserProfile')
          localStorage.setItem('userData', user.uid)
        //   this.$router.push('/')
        location.reload();
        },
        async fetchUserProfile({ commit }) {

        var token = localStorage.getItem("userData");
          // fetch user profile
          const userProfile = await fb.usersCollection.doc(token).get()
        //   //console.log(userProfile.data())
          localStorage.setItem('uData', JSON.stringify(userProfile.data()));
    
          // set user profile in state
          commit('setUserProfile', userProfile.data())
    
          // change route to dashboard
          if (router.currentRoute.path === '/login') {
            router.push('/')
          }

          var allData= firebase.database().ref('/Products').child('Books');
            // //console.log(booksData)
            allData.on('value',(snapshot)=>{
                var allBookObj = snapshot.val();
                //console.log(allBookObj)
                // this.fullData = allBookObj;
                commit('setData', allBookObj);

            })

        },
        async logout({ commit }) {
          // log user out
          await fb.auth.signOut()
          localStorage.removeItem('userData')
          localStorage.removeItem('uData')
          // clear user data from state
          commit('setUserProfile', {})
    
          // redirect to login view
        //   router.push('/')
        //   this.$router.push('/')
        location.reload();
        },
        // async createPost({ state, commit }, post) {
        //   // create post in firebase
        //   await fb.postsCollection.add({
        //     createdOn: new Date(),
        //     content: post.content,
        //     userId: fb.auth.currentUser.uid,
        //     userName: state.userProfile.name,
        //     comments: 0,
        //     likes: 0
        //   })
        // },
        // async likePost ({ commit }, post) {
        //   const userId = fb.auth.currentUser.uid
        //   const docId = `${userId}_${post.id}`
    
        //   // check if user has liked post
        //   const doc = await fb.likesCollection.doc(docId).get()
        //   if (doc.exists) { return }
    
        //   // create post
        //   await fb.likesCollection.doc(docId).set({
        //     postId: post.id,
        //     userId: userId
        //   })
    
        //   // update post likes count
        //   fb.postsCollection.doc(post.id).update({
        //     likes: post.likesCount + 1
        //   })
        // },
        // async updateProfile({ dispatch }, user) {
        //   const userId = fb.auth.currentUser.uid
        //   // update user object
        //   const userRef = await fb.usersCollection.doc(userId).update({
        //     name: user.name,
        //     title: user.title
        //   })
        //   // localStorage.setItem('usrName', user.name);
        //   // //console.log(user.name);
        //   dispatch('fetchUserProfile', { uid: userId })
    
        //   // update all posts by user
        // //   const postDocs = await fb.postsCollection.where('userId', '==', userId).get()
        // //   postDocs.forEach(doc => {
        // //     fb.postsCollection.doc(doc.id).update({
        // //       userName: user.name
        // //     })
        // //   })
    
        // //   // update all comments by user
        // //   const commentDocs = await fb.commentsCollection.where('userId', '==', userId).get()
        // //   commentDocs.forEach(doc => {
        // //     fb.commentsCollection.doc(doc.id).update({
        // //       userName: user.name
        // //     })
        // //   })
        // }
    }
});

export default store;
