<template>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="140"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide  v-for="product in carslData" :key="product.id" >
        <template v-slot:img>
          <img
            class="d-block img-fluid w-100"
            :src="product.image"
            alt="image slot"
          >
        </template>
      </b-carousel-slide>

      <!-- <b-carousel-slide>
        <template v-slot:img>
          <img
            class="d-block img-fluid w-100"
            src="https://firebasestorage.googleapis.com/v0/b/qupro-solutions.appspot.com/o/carousel%2Fcarph1.jpg?alt=media&token=586672e2-ab88-4fb3-b78f-3714d41d95c4"
            alt="image slot"
          >
        </template>
      </b-carousel-slide> -->

    </b-carousel>

    <!-- <p class="mt-4">
      Slide #: {{ slide }}<br>
      Sliding: {{ sliding }}
    </p> -->

</template>

<script>
import firebase from 'firebase';
  export default {
    data() {
      return {
        carslData: [],
        slide: 0,
        sliding: null
      }
    },
    mounted () {
    this.carsl();
  },
    methods: {
      onSlideStart() {
        this.sliding = true
      },
      onSlideEnd() {
        this.sliding = false
      },
      async carsl(){
      const snapshot = await firebase.firestore().collection("carousel").get();
      snapshot.forEach((doc) => {
        this.carslData.push(doc.data());
      });
    },
    }
  }
</script>