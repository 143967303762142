<template>
    <!-- <footer class="py-5 bg-dark">
        <div class="container">
            <p class="m-0 text-center text-white">Copyright © Emre Baskan 2019</p>
        </div>
    </footer> -->
    <footer id="aa-footer" style="z-index: 10;">
    <!-- footer bottom -->
    <div class="aa-footer-top">
     <div class="container">
        <div class="row">
        <div class="col-md-12">
          <div class="aa-footer-top-area">
            <div class="row">
              <div class="col-md-3 col-sm-6">
                <div class="aa-footer-widget" style="text-align: left;">
                  <h3>Main Menu</h3>
                  <ul class="aa-footer-nav list-unstyled" style="text-align: left;" >
                    <li><a >Home</a></li>
                    <li><a >Our Services</a></li>
                    <li><a >Our Products</a></li>
                    <li><a >About Us</a></li>
                    <li><a >Contact Us</a></li>
                    <li><a >FAQ</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-3 col-sm-6">
                <div class="aa-footer-widget">
                  <div class="aa-footer-widget" style="text-align: left;">
                    <h3>Knowledge Base</h3>
                    <ul class="aa-footer-nav list-unstyled" style="text-align: left;">
                      <li><a >Delivery</a></li>
                      <li><router-link to="/policies">Privacy Policy</router-link></li>
                      <li><a >Services</a></li>
                      <li><a >Discount</a></li>
                      <li><a >Special Offer</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6">
                <div class="aa-footer-widget">
                  <div class="aa-footer-widget" style="text-align: left;">
                    <h3>Products</h3>
                    <ul class="aa-footer-nav list-unstyled" style="text-align: left;">
                      <li><a>Site Map</a></li>
                      <li><a >Search</a></li>
                      <li><a >Advanced Search</a></li>
                      <li><a @click="checkAuth()">Suppliers</a></li>
                      
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6">
                <div class="aa-footer-widget">
                  <div class="aa-footer-widget" style="text-align: left;">
                    <a>
                        <h3>
                            <router-link to="/contact">Contact Us</router-link>
                        </h3>
                    </a>
                    <address>
                      <p>15, Basant Vihar Scheme 3, Alwar(Raj</p>
                      <p><span class="fa fa-phone"></span>(+91) 9653940783</p>
                      <p><span class="fa fa-envelope"></span>quprosolutions@gmail.com</p>
                    </address>
                    <div class="aa-footer-social">
                      <a href="https://facebook.com/qu.pro.5"><span class="fa fa-facebook"></span></a>
                      <a href="https://twitter.com/QuproS"><span class="fa fa-twitter"></span></a>
                      <a href="https://instagram.com/quprosolutions"><span class="fa fa-instagram"></span></a>
                      <a href="#"><span class="fa fa-youtube"></span></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     </div>
    </div>
    <!-- footer-bottom -->
    <!-- <div class="aa-footer-bottom">
      <div class="container">
        <div class="row">
        <div class="col-md-12">
          <div class="aa-footer-bottom-area">
            <p>Designed by <a href="http://www.markups.io/">MarkUps.io</a></p>
            <div class="aa-footer-payment">
              <span class="fa fa-cc-mastercard"></span>
              <span class="fa fa-cc-visa"></span>
              <span class="fa fa-paypal"></span>
              <span class="fa fa-cc-discover"></span>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div> -->
  </footer>

</template>

<script>
import link from './admin.vue';
import firebase from 'firebase';
import Vue from 'vue';
import VueRouter from 'vue-router'
  export default {
      name: "Footer",
      methods:{
        checkAuth(){
          // var token = localStorage.getItem("userData");

            firebase.auth().onAuthStateChanged((user) => {
              if (user) {
                // User logged in already or has just logged in.
                if(user.uid === '9qecdtB1bDRT7W4sT1aNEgMn79g2'){
                    this.goToAdmin();
                }else{
                  this.goToSuppliersPage();
                }
                // console.log(user.uid);
              } else {
                this.goToSuppliersPage();
              }
            });

            
        },
        goToSuppliersPage(){
          this.$router.push('/suppliers');
        },
        
        goToAdmin(){
            new Vue({
                VueRouter,
                
                render: h => h(link),

            }).$mount('#niQup')
        }
      }
  }
</script>

<style scoped>
/*==================
 FOOTER SECTION
====================*/

#aa-footer {
  background-color: #222;
  display: inline;
  float: left;
  width: 100%;
}
#aa-footer .aa-footer-top {
  display: inline;
  background-color: #222;
  float: left;
  padding: 50px 0;
  width: 100%;
  min-height: 100px;
}
#aa-footer .aa-footer-top .aa-footer-top-area {
  /* display: inline; */
  float: left;
  width: 100%;
}
#aa-footer .aa-footer-top .aa-footer-top-area .aa-footer-widget {
  display: block;
}
#aa-footer .aa-footer-top .aa-footer-top-area .aa-footer-widget h3 {
  color: #fff;
}
#aa-footer .aa-footer-top .aa-footer-top-area .aa-footer-widget .aa-footer-nav li a {
  color: #888;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
#aa-footer .aa-footer-top .aa-footer-top-area .aa-footer-widget .aa-footer-nav li a:hover, #aa-footer .aa-footer-top .aa-footer-top-area .aa-footer-widget .aa-footer-nav li a:focus {
  color: #fff;
}
#aa-footer .aa-footer-top .aa-footer-top-area .aa-footer-widget address p {
  margin-bottom: 5px;
  color: #888;
}
#aa-footer .aa-footer-top .aa-footer-top-area .aa-footer-widget address p span {
  margin-right: 5px;
}
#aa-footer .aa-footer-top .aa-footer-top-area .aa-footer-widget .aa-footer-social a {
  border: 1px solid #888;
  color: #888;
  display: inline-block;
  font-size: 18px;
  margin-right: 8px;
  padding: 2px 0;
  text-align: center;
  width: 35px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
#aa-footer .aa-footer-top .aa-footer-top-area .aa-footer-widget .aa-footer-social a:hover {
  color: #fff;
  border-color: #fff;
}
#aa-footer .aa-footer-bottom {
  border-top: 1px solid #333;
  display: inline;
  float: left;
  padding: 15px 0;
  width: 100%;
}
#aa-footer .aa-footer-bottom .aa-footer-bottom-area {
  display: inline;
  float: left;
  width: 100%;
}
#aa-footer .aa-footer-bottom .aa-footer-bottom-area > p {
  color: #888;
  display: inline-block;
  float: left;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 0;
}
#aa-footer .aa-footer-bottom .aa-footer-bottom-area > p a {
  color: #888;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
#aa-footer .aa-footer-bottom .aa-footer-bottom-area .aa-footer-payment {
  float: right;
}
#aa-footer .aa-footer-bottom .aa-footer-bottom-area .aa-footer-payment span {
  color: #888;
  margin-left: 8px;
}
#aa-footer .aa-footer-bottom .aa-footer-bottom-area .aa-footer-payment span:hover {
  color: #fff;
}
#aa-footer .aa-footer-bottom .aa-footer-bottom-area > p {
    width: 100%;
    text-align: center;
  }

  #aa-footer .aa-footer-bottom .aa-footer-bottom-area .aa-footer-payment {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
  #aa-footer .aa-footer-top .aa-footer-top-area .aa-footer-widget {
    margin-bottom: 30px;
    text-align: center;
  }
</style>
