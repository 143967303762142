<template>
    <!-- <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
        <div class="container">
            <router-link to="/products" class="navbar-brand"  >QuPro Solutions</router-link>
            <div>
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <router-link to="/cart" class="nav-link" ><i class="fa fa-shopping-cart mr-2"  aria-hidden="true" ></i>Cart
                            ({{$store.getters.cartLength}}) </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav> -->
<div>
  <b-navbar toggleable="lg" type="dark" variant="info" class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
    <b-navbar-brand @click="location.reload()">
      <img height="40px" width="40px" src="https://firebasestorage.googleapis.com/v0/b/qupro-solutions.appspot.com/o/logocs%20(2).png?alt=media&token=223d11f0-4948-4579-a34d-336b1788ae03" alt="">
      QuPro
    </b-navbar-brand>
    
    <b-navbar-toggle target="nav-collapse">
      <router-link to="/cart"  style="color: #fff">
      <i class="fa fa-shopping-cart" ></i>
      ({{$store.getters.cartLength}}) </router-link>
    </b-navbar-toggle>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item>
          <router-link to="/products" style="color: #fff">Home</router-link>
        </b-nav-item>
        <!-- <b-nav-item @click="sendEmail()">send Mail</b-nav-item> -->
        <!-- <b-nav-item-dropdown>
          <template v-slot:button-content>
            <em style="color: #fff">Categories</em>
          </template>
          <b-dropdown-item @click="goToCat('Clothes')">Clothes</b-dropdown-item>
          <b-dropdown-item @click="goToCat('Mobiles')">Mobiles/Accessories</b-dropdown-item>
          <b-dropdown-item @click="goToCat('Electronics')">Electronics</b-dropdown-item>
          <b-dropdown-item @click="goToCat('Shoes')">Shoes</b-dropdown-item>
          <b-dropdown-item @click="goToCat('Jewellery')">Jewellery</b-dropdown-item>
          <b-dropdown-item @click="goToCat('Cosmetics')">Cosmetics</b-dropdown-item>
          <b-dropdown-item @click="goToCat('Books')">Books</b-dropdown-item>
          <b-dropdown-item @click="goToCat('Sports_Equipments')">Sport Equipments</b-dropdown-item>
          <b-dropdown-item @click="goToCat('Construction_Material')">Construction Material</b-dropdown-item>
          <b-dropdown-item @click="goToCat('AutoMobiles')">Auto Mobiles</b-dropdown-item>
          <b-dropdown-item @click="goToCat('Furniture')">Furniture</b-dropdown-item>
        </b-nav-item-dropdown> -->

        <!-- <b-nav-item-dropdown>
            <template v-slot:button-content>
              <em  style="color: #fff">Daily Needs</em>
            </template>
            <b-dropdown-item @click="goToCat('Grocery')">Grocery</b-dropdown-item>
            <b-dropdown-item @click="goToCat('Food')">Food</b-dropdown-item>
            <b-dropdown-item @click="goToCat('Vegetables')">Vegetables/Fruits</b-dropdown-item>
            <b-dropdown-item @click="goToCat('Bag_Bottles')">Bag & Bottles</b-dropdown-item>
            <b-dropdown-item @click="goToCat('Kitchen')">Kitchen Accessories</b-dropdown-item>
            <b-dropdown-item @click="goToCat('Gifts')">Toys & Gifts</b-dropdown-item>
            <b-dropdown-item @click="goToCat('Home_Utilities')">Home Utilities</b-dropdown-item>
            <b-dropdown-item @click="goToCat('Utencils')">Utencils</b-dropdown-item>
            
          </b-nav-item-dropdown> -->
        <b-nav-item  style="color: #fff">
          <router-link to="/aboutus" style="color: #fff">About Us</router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link to="/contact" style="color: #fff">Contact Us</router-link>
        </b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-form @submit="search()">
          <b-form-input size="sm" v-model="txtForSrch" class="mr-sm-2" placeholder="Search"></b-form-input>
          <b-button size="sm" class="my-2 my-sm-0" type="submit"><i class="fa fa-search"></i></b-button>
        </b-nav-form>

        <!-- <b-nav-item-dropdown text="Lang" right>
          <b-dropdown-item href="#">EN</b-dropdown-item>
          <b-dropdown-item href="#">ES</b-dropdown-item>
          <b-dropdown-item href="#">RU</b-dropdown-item>
          <b-dropdown-item href="#">FA</b-dropdown-item>
        </b-nav-item-dropdown> -->
        
        <li class="nav-item">
          <router-link to="/cart" class="nav-link"  style="color: #fff">
          <i class="fa fa-shopping-cart mr-1"  aria-hidden="true" ></i>
          Cart ({{$store.getters.cartLength}}) </router-link>
        </li>
        <b-nav-item-dropdown right  v-if="user.token == '' || user.token == null || user.token == undefined">
          <!-- Using 'button-content' slot -->
          <template v-slot:button-content>
            <em  style="color: #fff">Account</em>
          </template>
          <b-dropdown-item  v-b-modal.modalSignIn>Login</b-dropdown-item>
          <b-dropdown-item  v-b-modal.modalSignUp>Sign Up</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown right  v-else-if="user.token != '' && user.token != null && user.token != undefined">
          <!-- Using 'button-content' slot -->
          <template v-slot:button-content>
            <em  style="color: #fff">{{user.nameuser}}</em>
          </template>
          <b-dropdown-item >
            <router-link to="/profile" style="color: #000" >Profile</router-link>
          </b-dropdown-item>
          <b-dropdown-item v-on:click="logOut()">Log Out</b-dropdown-item>
        </b-nav-item-dropdown>

         <!-- <b-nav-item right  v-else-if="token != ''">
          <router-link to="/contact" style="color: #fff">{{token}}</router-link>
        </b-nav-item> -->
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
                
            <!-- Login Model is here -->
            <b-modal id="modalSignIn" centered title="Login Now" hide-footer>
                <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group  label="E-Mail" label-for="email-input" invalid-feedback="E-Mail is required">
                <b-form-input v-model.trim="loginForm.email" id="email-log" required ></b-form-input>
                </b-form-group>

                <b-form-group label="Password" label-for="pass-input" invalid-feedback="Password is required">
                <b-form-input v-model.trim="loginForm.password" id="pass-log" type="password" required ></b-form-input>
                </b-form-group>

                <div class="row">
                    <div class="form-group ml-3">
                        <p>Haven't Registered Yet?  <a style="color:blue;" @click="$bvModal.hide('modalSignIn')" v-b-modal.modalSignUp>SignUp</a></p>
                    </div>
                </div>
                </form>
                <b-overlay :show="show" rounded="sm">
                <b-card :aria-hidden="show ? 'true' : null">            
                    <b-button class="mt-3" variant="outline-success" block :disabled="show" @click="login()">Login</b-button>
                </b-card>
                </b-overlay>
            </b-modal>

            <!-- Sign Up Model is here -->
            <b-modal id="modalSignUp" title="Register Yourself" hide-footer>
                <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group label="Your Full Name" label-for="name-input" invalid-feedback="Name is required">
                    <b-form-input v-model.trim="signupForm.name" id="name-reg" v-b-tooltip title="It will be shown in your certificate." required ></b-form-input>
                </b-form-group>

                <b-form-group label="E-Mail" label-for="email-input" invalid-feedback="E-Mail is required">
                    <b-form-input v-model.trim="signupForm.email" id="email-reg" required ></b-form-input>
                </b-form-group>

                <b-form-group label="Phone Number" label-for="phone-input" invalid-feedback="Phone no. is required">
                    <b-form-input v-model.trim="signupForm.phn" id="call-reg" required ></b-form-input>
                </b-form-group>

                <b-form-group label="PINCODE" label-for="pin-input" invalid-feedback="PINCODE is required">
                    <b-form-input v-model.trim="signupForm.pin" id="pin-reg" required ></b-form-input>
                </b-form-group>

                <b-form-group label="Address" label-for="address-input" invalid-feedback="Address is required">
                    <b-form-input v-model.trim="signupForm.addr" id="addr-reg" required ></b-form-input>
                </b-form-group>

                <b-form-group label="About You" label-for="title-input" invalid-feedback="Type something about you">
                    <b-form-input v-model.trim="signupForm.title" id="about-reg" required ></b-form-input>
                </b-form-group>

                <b-form-group label="Password" label-for="pass-reg" invalid-feedback="Password is required">
                    <b-form-input v-model.trim="signupForm.password" id="pass-reg" type="password" required ></b-form-input>
                </b-form-group>

                <b-form-group label="Confirm Password" label-for="pass-c-reg" invalid-feedback="Confirm Password is required">
                    <b-form-input v-model.trim="signupForm.cPassword" id="pass-c-reg" type="password" required ></b-form-input>
                </b-form-group>

                <!-- <b-form-group label="Referral code" label-for="code-input">
                    <b-form-input v-model.trim="signupForm.code" id="code-reg" required ></b-form-input>
                </b-form-group> -->

                <div class="row">
                    <div class="form-group ml-3">
                        <p>Already Registered?  <a style="color:blue;" @click="$bvModal.hide('modalSignUp')" v-b-modal.modalSignIn>SignIn</a></p>
                    </div>
                </div>
                </form>
                <b-overlay :show="show" rounded="sm">
                <b-card :aria-hidden="show ? 'true' : null"> 
                    <b-button class="mt-3" variant="outline-primary" block :disabled="show"  @click="signup()">Register</b-button>
                </b-card>
                </b-overlay>
            </b-modal>
            
</div>
</template>

<script>
// import sendemail from 'sendemail';
import firebase from 'firebase';
    export default {
        name: "Header",
        data(){
          return{
            show: false,
            txtForSrch: null,

            user:{
              token: '',
              nameuser: ''
            },
            

            loginForm: {
              email: '',
              password: ''
            },
            signupForm: {
              name: '',
              title: '',
              email: '',
              password: '',
              cPassword: '',
              phn: '',
              pin: '',
              addr: ''
            },
          }
        },
        mounted(){
          this.$store.dispatch('fetchUserProfile');

          firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              // User logged in already or has just logged in.
              this.getProfile();
              // console.log(user.uid);
            } else {
              // this.logOut();
            }
          });
        },
        methods:{

          search(){
            this.$router.push('/search/'+this.txtForSrch);
          },

          sendEmail() {

            // window.Email.send({
            // Host: "smtp.gmail.com",
            // Username : "attituddsharma@gmail.com",
            // Password : "8952842505",
            // To : 'jitendrakumarsaini9929@gmail.com',
            // From : "attituddsharma@gmail.com",
            // Subject : "email subject",
            // Body : "email body",
            // Attachments : [
            //   {
            //     name : "smtpjs.png",
            //     path:"https://networkprogramming.files.wordpress.com/2017/11/smtpjs.png"
            //   }]
            // }).then(
            //   message => alert("mail sent successfully\n"+message)
            // );
            var sendemail   = require('sendemail');
          
            var options = {
              templateName: 'hello',
              context: {
                tempalateVariableName: 'Variable Value',
                name: 'Joe Bloggs'
              },
              subject: 'Welcome to Email',
              senderEmailAddress: 'mitrcnishant@gmail.com',
              toAddresses: ['jitendrakumarsaini9929@gmail.com'],
              ccAddresses: ['ccRecipient1@gmail.com', 'ccRecipient2@gmail.com'],
              bccAddresses: ['bccRecipient1@gmail.com', 'bccRecipient2@gmail.com'],
              htmlCharset: 'utf16',
              textCharset: 'utf16',
              subjectCharset: 'utf8'
            };

            sendemail.sendMany(options);

          },

          goToCat(catName){
            this.$router.push('/products/'+catName)
          },

          login() {
            this.show = true;
            this.$store.dispatch('login', {
              email: this.loginForm.email,
              password: this.loginForm.password
            })
            // setInterval(this.fal(), 4000);
            this.intervalid1 = setInterval(function(){
                this.show = false
            }.bind(this), 5000);
          },
          signup() {
            this.show = true;
            this.$store.dispatch('signup', {
              email: this.signupForm.email,
              password: this.signupForm.password,
              name: this.signupForm.name,
              title: this.signupForm.title,
              phn: this.signupForm.phn,
              pin: this.signupForm.pin,
              addr: this.signupForm.addr,
            })
            // setInterval(this.fal(), 4000);
            this.intervalid1 = setInterval(function(){
                this.show = false
            }.bind(this), 5000);
          },
          
          getProfile() {
            // var storedNames;
            this.user.token = localStorage.getItem("userData");
            //console.log(this.user.token);
            // this.name = firebase.firestore().collection('users').doc(this.token).get().data().name;
            // if(this.token){
            //   firebase.firestore().collection('users').doc(this.token).get()
            //       .then((snapshot) => {
            //     this.name = snapshot.name;
            //     //console.log(this.name);
            //   })
            // }

            // var docRef = firebase.firestore().collection("users").doc(this.user.token);

            // docRef.get().then(function(doc) {
            //         storedNames = doc.data().name;
            //         //console.log(storedNames);
            // })
            
            // if(storedNames != null){
              this.user.nameuser = JSON.parse(localStorage.getItem('uData')).name;
              //console.log(this.user.nameuser)
              // var local = localStorage.getItem('uData');
              // //console.log()
              // //console.log(local);
            //   //console.log(storedNames);
            // }
          },
          logOut(){
            this.$store.dispatch('logout')
          }
        }
    }
</script>

<style scoped>

</style>
