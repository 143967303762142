<template>
  <div class="products pt-5">
    <div class="container pt-3">
      <div class="h-100">
        <div class="row h-100 justify-content-left align-items-center">
          <div class="col-lg-6">
            <h3>Admin Portal</h3>

            <p>QuPro Products CRUD page.</p>
          </div>
          <div class="col-lg-6">
            <button class="btn btn-primary" @click="orderPage(1)">
              Products
            </button>
            <button class="btn btn-danger ml-2" @click="orderPage(2)">
              Orders
            </button>
            <button class="btn btn-warning ml-2" @click="orderPage(4)">
              Shops
            </button>
            <button class="btn btn-success ml-2" @click="orderPage(5)">
              Shop Products
            </button>
            <button class="btn btn-secondary ml-2" @click="orderPage(3)">
              carousels
            </button>
          </div>
        </div>
      </div>
    </div>
      <div id="uploadPrd">

      </div>
  </div>
</template>

<script>
import order from "./orders.vue";
import shops from "../shop/shopOrders.vue";
import shoProducts from "../shop/shopProducts.vue";
import products from "./uploadNew.vue";
import Carosl from "./uplCarosl.vue";
import Vue from "vue";
import VueRouter from "vue-router";

export default {
    data(){},
    methods:{
        orderPage(x) {
      if (x == 1) {
        new Vue({
          VueRouter,

          render: (h) => h(products),
        }).$mount("#uploadPrd");
      }else if (x == 2) {
        new Vue({
          VueRouter,

          render: (h) => h(order),
        }).$mount("#uploadPrd");
      } else if (x == 3) {
        new Vue({
          VueRouter,

          render: (h) => h(Carosl),
        }).$mount("#uploadPrd");
      } else if (x == 4) {
        new Vue({
          VueRouter,

          render: (h) => h(shops),
        }).$mount("#uploadPrd");
      }else if (x == 5) {
        new Vue({
          VueRouter,

          render: (h) => h(shoProducts),
        }).$mount("#uploadPrd");
      }
    },
    }
}
</script>