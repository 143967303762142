<template>
    <div class="row center pr-2 pl-2" style="padding-top: 6rem;">
        <div class="card shopping-cart col-lg-8">
            <div class="card-header bg-dark text-light">
                <i class="fa fa-shopping-cart pr-2" aria-hidden="true"></i>
                Shopping cart
                <div class="clearfix"></div>
            </div>
            <div class="card-body">
                <ShoppingCartItem
                        v-for="(cartItem,index) in $store.state.cart"
                        :key="index"
                        :product="cartItem"
                />
                <h2 class="display-4 text-center" v-if="$store.state.cart.length === 0">
                    Your cart is empty...
                </h2>
            </div>
            <div class="card-footer">
                <div class="pull-right" style="margin: 10px">
                    <div class="pull-right" style="margin: 5px">
                            <button class="btn btn-success" @click="buyNow($store.getters.totalPrice, $store.state.cart)">
                                Check Out: ₹{{$store.getters.totalPrice}}
                            </button>
                    </div>
                    <!-- <div class="pull-right" style="margin: 5px">
                        Total price: <b>{{$store.getters.totalPrice | priceFormatter}}$</b>
                    </div> -->
                    
                </div>
            </div>
        </div>
        <div class="card shopping-cart col-lg-4">
            <div class="card-header bg-dark text-light">
                <i class="fa fa-user pr-2" aria-hidden="true"></i>
                Customer Info.
                <!-- <div class="clearfix"></div> -->
            </div>
            <p>Delivery Address:</p>
            <p><b>{{user.name}}</b></p>
            <small><b>{{user.email}}</b></small>
            <small><b>{{user.phn}}</b></small>
            <small>{{user.addr}}</small>
            <small>{{user.city}}</small>
            <p>{{user.pin}}</p>

            <div class="card-header bg-dark text-light">
                <i class="fa fa-user pr-2" aria-hidden="true"></i>
                PRICE DETAILS
            </div>
            <div
                v-for="(cartItem,index) in $store.state.cart"
                :key="index">
                <!-- <p>{{cartItem.proKey}}</p> -->
                <p>{{cartItem.title}} <strong> x {{cartItem.quantity}}</strong> : <b class="float-right">₹{{cartItem.price}}</b> </p>
            </div>
            <hr>
            <div>
                Total Amount: <b class="float-right">₹{{$store.getters.totalPrice}}</b>
            </div>

        </div>
    </div>
</template>

<script>
    import ShoppingCartItem from "./ShoppingCartItem";
    import firebase from 'firebase';
    // import firebase from 'firebase';
    export default {
        name: "ShoppingCartContainer",
        data(){
            return{
                user:[]
            }
        },
        components: {ShoppingCartItem},
        mounted(){
            // console.log(Object.keys(this.$store.state.cart)) 
            this.user = JSON.parse(localStorage.getItem('uData'));
        },
        methods:{
            buyNow(rupee, product){
                    firebase.auth().onAuthStateChanged((user) => {
                        if(user.uid != '' && user.uid != null && user.uid != undefined && user){
                            // var iDs = [];
                            // console.log(product);
                            // for(var i in product){
                            //     // console.log(item);
                            //     iDs.push(product[i].proKey);
                            // }
                            // console.log(iDs);
                            this.buyN(rupee, product);
                        }else{
                            alert('You have to login first.')
                        }
                    })
                },

            buyN: function (rupee, prod) {
            var payable = rupee * 100 + ((rupee * 100) / 100) * 2;

            var alpha = [
                "A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z","1","2","3","4","5","6","7","8","9","0"
            ];
            var nums = ['1','2','3','4','5','6','7','8','9','0'];

            var one = alpha[Math.floor(Math.random() * alpha.length)];
            var two = alpha[Math.floor(Math.random() * alpha.length)];
            var thr = nums[Math.floor(Math.random() * nums.length)];
            var frt = alpha[Math.floor(Math.random() * alpha.length)];
            var fiv = nums[Math.floor(Math.random() * nums.length)];
            var six = nums[Math.floor(Math.random() * nums.length)];

            var order = one+two+thr+frt+fiv+six;

            let rzp1 = new window.Razorpay({
                key: "rzp_live_l7EWMLcpJPWY3s",
                // key_secret: 'EsYn4FdxR2ZcKtmGPzwCwdgi',
                amount: payable,
                name: "QuPro Solutions",
                currency: "INR",
                description: "Check Out for your Product.",
                image:
                "https://firebasestorage.googleapis.com/v0/b/qupro-solutions.appspot.com/o/logo.png?alt=media&token=f907f861-e243-4161-836b-030c58049c49",
                //Uncomment if you are using handler function
                handler: function (response) {
                // razorOrderId = response.razorpay_order_id
                // razorPaymentId = response.razorpay_payment_id
                // razorSignature = response.razorpay_signature
                // alert(response.razorpay_payment_id);

                var token = localStorage.getItem("userData");
                for(var i in prod){

                    firebase.firestore().collection("users").doc(token).collection("orders").doc(prod[i].proKey)
                    .set({
                        'orderId': order,
                        'paymentId': response.razorpay_payment_id,
                        // 'signature': response.razorpay_signature,
                        'qty': prod[i].quantity
                    }); 
                    firebase.database().ref('/shopData').child(prod[i].shopId).child('Orders').push()
                    // firebase.firestore().collection("shop").doc().collection("orders").doc(prod[i].proKey)
                    .set({
                        // 'orderId': response.razorpay_order_id,
                        'orderId': order,
                        'productId': prod[i].proKey,
                        'paymentId': response.razorpay_payment_id,
                        // 'signature': response.razorpay_signature,
                        'qty': prod[i].quantity
                    }); 

                }
                //set/copy the data to new path.

                },
                // callback_url: 'http://13.126.183.214/razorpay/checkstatus/?orderid='+this.order_id,
                // prefill: {
                // name: "Nishant Sharma",
                // email: "rjnishant49@gmail.com",
                // },
                notes: {
                address: "Scheme 3, Alwar",
                },
                theme: {
                color: "#00ffff",
                },
                // order_id: shopId,
                // callback_url: "http://localhost:8081/",
                // redirect: true
            });

            rzp1.open();
            },
        }
    }
</script>

<style scoped>

</style>
