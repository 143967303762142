<template>
    <div class="profile-page pt-5">
        <section class="section-profile-cover section-shaped my-0">
            <div class="shape shape-style-1 shape-primary shape-skew alpha-4">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </section>
        <section class="section section-skew">
            <div class="container">
                <card shadow class="card-profile mt--300" no-body>
                    <div class="px-4">
                        
                        <div class="text-center mt-5">
                            <h3>{{user.name}}
                            </h3>
                            <div class="h6 font-weight-300"><i class="ni location_pin mr-2"></i>{{user.email}}</div>
                            <div class="h6 font-weight-300">Your Coins: <i class="fa fa-inr"></i>{{user.coins}}</div>
                            <div class="h6 mt-4"><i class="fa fa-share mr-2"></i> Raferral Code: <b style="color: blue;">{{user.raferral}}</b></div>
                            <div class="h6 mt-4"><i class="fa fa-phone mr-2"></i> Phone: <b>{{user.phn}}</b></div>
                            <div><i class="fa fa-address-card-o"></i> Address: <b>{{user.address}}</b></div>
                            <div><i class="fa fa-map-marker"></i> Pin: <b>{{user.pin}}</b></div>
                        </div>
                        <div class="mt-5 py-5 border-top text-center">
                            <div class="row justify-content-center">
                                <div class="col-lg-9">
                                    <p>{{user.title}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </section>
        <!-- <section>
          <button>
            Update Profile
          </button>
        </section> -->
    </div>
</template>
<script>
export default {
  data(){
    return{
      show: false,

      user:{
        user: []
      },
    }
  },
  mounted(){
    this.user = JSON.parse(localStorage.getItem('uData'));
  }
};
</script>
<style>
</style>

