<template>
  <div class="products">
    <div class="container">
      <!-- <div class="h-100">
        <div class="row h-100 justify-content-left align-items-center">
          <div class="col-lg-8">
            <h3>Admin Portal</h3>

            <p>QuPro Products CRUD page.</p>
          </div>
          <div class="col-lg-4">
            <button class="btn btn-primary" @click="orderPage(1)">Products</button>
            <button class="btn btn-primary ml-2" @click="orderPage(2)">Orders</button>
            <button class="btn btn-secondary ml-2" @click="orderPage(3)">carousels</button>
          </div>
        </div>
      </div> -->

      <!-- <hr /> -->
      <div id="uploadPrd">
        <div aria-labelledby="editLabel">
          <div class="col-lg-12" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="editLabel">Upload Verified Product</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div>
                <div class="row">
                  <!-- main product -->
                  <div class="col-md-8">
                    <div class="row">
                      <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Name"
                          v-model="product.title"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <b-form-select
                          class="form-control"
                          v-model="product.category"
                          :options="options"
                          id="PostCat"
                        >
                        </b-form-select>
                      </div>

                      <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Camera"
                          v-model="product.camera"
                          class="form-control"
                        />
                      </div> -->

                      <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Battery"
                          v-model="product.battery"
                          class="form-control"
                        />
                      </div> -->

                      <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Brand"
                          v-model="product.brand"
                          class="form-control"
                        />
                      </div>

                      <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Cpu"
                          v-model="product.cpu"
                          class="form-control"
                        />
                      </div> -->

                      <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Display"
                          v-model="product.display"
                          class="form-control"
                        />
                      </div> -->

                      <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          v-if="product.category == 'Mobiles'"
                          placeholder="Product Ram"
                          v-model="product.ram"
                          class="form-control"
                        />
                      </div> -->

                      <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Rom"
                          v-model="product.rom"
                          class="form-control"
                        />
                      </div> -->

                      <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Size"
                          v-model="product.size"
                          class="form-control"
                        />
                      </div> -->

                      <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Weight"
                          v-model="product.weight"
                          class="form-control"
                        />
                      </div> -->

                      <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="product Id"
                          v-model="product.id"
                          class="form-control"
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="ShopId Id"
                          v-model="product.shopId"
                          class="form-control"
                          :disabled="true"
                        />
                      </div>

                      <div class="form-group  col-md-6">
                        <input
                          type="number"
                          placeholder="Product price"
                          v-model="product.price"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group  col-md-6">
                        <input
                          type="number"
                          placeholder="Product MRP"
                          v-model="product.mrp"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group  col-md-6">
                        <input
                          type="number"
                          placeholder="Coins Applied"
                          v-model="product.coins"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group  col-md-6">
                        <input
                          type="number"
                          placeholder="Product Quantity"
                          v-model="product.qty"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <!-- <vue-editor v-model="product.description"></vue-editor> -->
                      <textarea
                        placeholder="Product Description"
                        class="col-lg-12"
                        rows="10"
                        v-model="product.description"
                      ></textarea>
                    </div>
                  </div>
                  <!-- product sidebar -->
                  <div class="col-md-4">
                    <h4 class="display-6">Product Details</h4>
                    <hr />

                    <div class="form-group">
                      <input
                        type="text"
                        placeholder="Type"
                        v-model="choose.tpe"
                        class="form-control"
                      />
                      <input
                        type="text"
                        placeholder="Color"
                        v-model="choose.color"
                        class="form-control"
                      />
                      <input
                        type="number"
                        placeholder="Type MRP"
                        v-model="choose.mrp"
                        class="form-control"
                      />
                      <input
                        type="number"
                        placeholder="Type Price"
                        v-model="choose.price"
                        class="form-control"
                      />
                      <input
                        type="number"
                        placeholder="Type Coins"
                        v-model="choose.coins"
                        class="form-control"
                      />
                      <input
                        type="number"
                        placeholder="Quantity"
                        v-model="choose.qty"
                        class="form-control"
                      />

                      <button
                        class="btn btn-block btn-primary"
                        @click="addChoice"
                      >
                        Add Choice
                      </button>

                      <div class="d-fle">
                        <p v-for="itm in product.chooser" :key="itm._id">
                          <span class="p-1">
                            {{ itm }}
                          </span>
                        </p>
                      </div>
                    </div>

                    <hr />

                    <div class="form-group">
                      <input
                        type="text"
                        @keyup.188="addTag"
                        placeholder="Product tags"
                        v-model="tag"
                        class="form-control"
                      />

                      <div class="d-flex">
                        <p v-for="tag in product.tags" :key="tag._id">
                          <span class="p-1">{{ tag }}</span>
                        </p>
                      </div>
                    </div>


                    <div class="form-group">
                      <label for="product_image">Product Images</label>
                      <input
                        type="text"
                        placeholder="Enter Image URL"
                        v-model="imgTag"
                        @keypress.13="addImg"
                        class="form-control mb-2"
                        v-if="!product.images[0]"
                      />

                      <input
                        type="file"
                        @change="uploadImage"
                        class="form-control"
                        v-if="!product.images[0]"
                      />
                    </div>

                    <div class="form-group d-flex">
                      <div
                        class="p-1"
                      >
                        <div class="img-wrapp" v-for="img in product.images" :key="img.id">
                          <img :src="img" alt="" width="80px" />
                          <!-- <span
                            class="delete-img"
                            @click="deleteImage(product.images[0], index)"
                            >X</span
                          > -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div>
                  <gallery :images="images" :index="index" @close="index = null"></gallery>
                      <div
                        class="image"
                        v-for="(image, imageIndex) in images"
                        :key="imageIndex"
                        @click="index = imageIndex"
                        :style="{ backgroundImage: 'url(' + image + ')', width: '300px', height: '200px' }"
                      ></div>
                </div> -->
              </div>
              <div class="modal-footer" style="text-align: right">
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="reset()"
                >
                  Reset
                </button>
                <button
                  @click="uploadData()"
                  type="button"
                  class="btn btn-primary"
                >
                  Save changes
                </button>
                <!-- <button @click="updateProduct()" type="button" class="btn btn-primary" v-if="modal == 'edit'">Apply changes</button> -->
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="product-test">
          <h3 class="d-inline-block">New QuPro Products list</h3>
          <!-- <button @click="addNew" class="btn btn-primary float-right">Add Product</button> -->

          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Price</th>

                  <th>Modify</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="prOne in proKey2" :key="prOne.id">
                  <td>
                    {{ products2[prOne].title }}
                  </td>

                  <td>
                    {{ products2[prOne].price }}
                  </td>

                  <td>
                    <button
                      class="btn btn-primary"
                      @click="editProduct(products2[prOne], prOne)"
                    >
                      <i class="fa fa-edit"></i>
                    </button>
                    <button
                      class="btn btn-danger ml-1"
                      @click="deleteProduct(prOne)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <hr />

        <div class="product-test">
          <h3 class="d-inline-block">Old Products list</h3>
          <!-- <button @click="addNew" class="btn btn-primary float-right">Add Product</button> -->

          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Price</th>

                  <th>Modify</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="prOne in proKey" :key="prOne.id">
                  <td>
                    {{ products[prOne].title }}
                  </td>

                  <td>
                    {{ products[prOne].price }}
                  </td>

                  <td>
                    <button
                      class="btn btn-primary"
                      @click="editProduct(products[prOne], prOne)"
                    >
                      <i class="fa fa-edit"></i>
                    </button>
                    <button
                      class="btn btn-danger ml-1"
                      @click="deleteProduct(prOne)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Modal -->
        <div
          class="modal fade"
          id="product"
          tabindex="-1"
          role="dialog"
          aria-labelledby="editLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="editLabel">Edit Product</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <!-- main product -->
                  <div class="col-md-8">
                    <div class="row">
                      <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Name"
                          v-model="product.title"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <b-form-select
                          class="form-control"
                          v-model="product.category"
                          :options="options"
                          id="PostCat"
                        >
                        </b-form-select>
                      </div>

                      <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Camera"
                          v-model="product.camera"
                          class="form-control"
                        />
                      </div> -->

                      <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Battery"
                          v-model="product.battery"
                          class="form-control"
                        />
                      </div> -->

                      <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Brand"
                          v-model="product.brand"
                          class="form-control"
                        />
                      </div>

                      <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Cpu"
                          v-model="product.cpu"
                          class="form-control"
                        />
                      </div> -->

                      <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Display"
                          v-model="product.display"
                          class="form-control"
                        />
                      </div> -->

                      <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          v-if="product.category == 'Mobiles'"
                          placeholder="Product Ram"
                          v-model="product.ram"
                          class="form-control"
                        />
                      </div> -->

                      <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Rom"
                          v-model="product.rom"
                          class="form-control"
                        />
                      </div> -->

                      <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Size"
                          v-model="product.size"
                          class="form-control"
                        />
                      </div> -->

                      <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Weight"
                          v-model="product.weight"
                          class="form-control"
                        />
                      </div> -->

                      <div class="form-group col-md-6">
                        <input
                          type="number"
                          placeholder="product Id"
                          v-model="product.id"
                          class="form-control"
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="shop Id"
                          v-model="product.shopId"
                          class="form-control"
                          :disabled="true"
                        />
                      </div>

                      <div class="form-group  col-md-6">
                        <input
                          type="number"
                          placeholder="Product price"
                          v-model="product.price"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group  col-md-6">
                        <input
                          type="number"
                          placeholder="Product MRP"
                          v-model="product.mrp"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group  col-md-6">
                        <input
                          type="number"
                          placeholder="Coins Applied"
                          v-model="product.coins"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group  col-md-6">
                        <input
                          type="number"
                          placeholder="Product Quantity"
                          v-model="product.qty"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <!-- <vue-editor v-model="product.description"></vue-editor> -->
                      <textarea
                        placeholder="Product Description"
                        class="col-lg-12"
                        rows="10"
                        v-model="product.description"
                      ></textarea>
                    </div>
                  </div>
                  <!-- product sidebar -->
                  <div class="col-md-4">
                    <h4 class="display-6">Product Details</h4>
                    <hr />

                    <div class="form-group">
                      <input
                        type="text"
                        placeholder="Type"
                        v-model="choose.tpe"
                        class="form-control"
                      />
                      <input
                        type="text"
                        placeholder="Color"
                        v-model="choose.color"
                        class="form-control"
                      />
                      <input
                        type="number"
                        placeholder="Type MRP"
                        v-model="choose.mrp"
                        class="form-control"
                      />
                      <input
                        type="number"
                        placeholder="Type Price"
                        v-model="choose.price"
                        class="form-control"
                      />
                      <input
                        type="number"
                        placeholder="Type Coins"
                        v-model="choose.coins"
                        class="form-control"
                      />
                      <input
                        type="number"
                        placeholder="Quantity"
                        v-model="choose.qty"
                        class="form-control"
                      />

                      <button
                        class="btn btn-block btn-primary"
                        @click="addChoice()"
                      >
                        Add Choice
                      </button>

                      <div class="d-fle">
                        <p v-for="itm in product.chooser" :key="itm._id">
                          <span class="p-1">
                            {{ itm }}
                          </span>
                        </p>
                      </div>
                    </div>

                    <hr />

                    <div class="form-group">
                      <input
                        type="text"
                        @keyup.188="addTag"
                        placeholder="Product tags"
                        v-model="tag"
                        class="form-control"
                      />

                      <div class="d-flex">
                        <p v-for="tag in product.tags" :key="tag._id">
                          <span class="p-1">{{ tag }}</span>
                        </p>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="product_image">Product Images</label>
                      <input
                        type="file"
                        @change="uploadImage"
                        class="form-control"
                      />
                    </div>

                    <div class="form-group d-flex">
                      <div
                        class="p-1"
                      >
                        <div class="img-wrapp">
                          <img :src="product.images" alt="" width="80px" />
                          <!-- <span
                            class="delete-img"
                            @click="deleteImage(product.images[0], index)"
                            >X</span
                          > -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div>
                  <gallery :images="images" :index="index" @close="index = null"></gallery>
                      <div
                        class="image"
                        v-for="(image, imageIndex) in images"
                        :key="imageIndex"
                        @click="index = imageIndex"
                        :style="{ backgroundImage: 'url(' + image + ')', width: '300px', height: '200px' }"
                      ></div>
                </div> -->
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  @click="uploadData()"
                  type="button"
                  class="btn btn-primary"
                  v-if="modal == 'new'"
                >
                  Save changes
                </button>
                <button
                  @click="updateProduct()"
                  type="button"
                  class="btn btn-primary"
                  v-if="modal == 'edit'"
                >
                  Apply changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import Swal from "sweetalert2";
import $ from "jquery";

// import order from "./orders.vue";
// import Carosl from "./uplCarosl.vue";
// import Vue from "vue";
// import VueRouter from "vue-router";

window.Swal = Swal;
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

window.Toast = Toast;

export default {
  name: "Products",
  // components: {
  //   'gallery': VueGallery,
  // },
  props: {
    msg: String,
  },

  data() {
    return {
      products: [],
      proKey: [],
      products2: [],
      proKey2: [],
      editKey: "",
      product: {
        title: null,
        description: null,
        price: null,
        tags: [],
        images: [],
        chooser: [],
        battery: null,
        brand: null,
        camera: null,
        cpu: null,
        display: null,
        id: '0',
        qty: null,
        ram: null,
        rom: null,
        shopId: "Qu@Pro",
        size: null,
        uid: null,
        weight: null,
        category: null,
      },
      choose: {
        tpe: null,
        color: null,
        mrp: null,
        price: null,
        coins: null,
        qty: null,
      },
      options: [
        { value: "Grocery", text: "Grocery" },
        { value: "Latest", text: "Latest" },
        { value: "Trending", text: "Trending" },
        { value: "Clothes", text: "Clothes" },
        { value: "Mobiles", text: "Mobiles" },
        { value: "Electronics", text: "Electronics" },
        { value: "Shoes", text: "Shoes" },
        { value: "Jewellery", text: "Jewellery" },
        { value: "Vegetables", text: "Vegetables" },
        { value: "Cosmetics", text: "Cosmetics" },
        { value: "Books", text: "Books" },
        { value: "Utencils", text: "Utencils" },
        { value: "Gifts", text: "Gifts" },
        { value: "Bag_Bottles", text: "Bag_Bottles" },
        { value: "Kitchen", text: "Kitchen" },
        { value: "Home_Utilities", text: "Home_Utilities" },
        { value: "Sports_Equipments", text: "Sports_Equipments" },
        { value: "Construction_Material", text: "Construction_Material" },
        { value: "Furniture", text: "Furniture" },
        { value: "Food", text: "Food" },
        { value: "Auto_Mobiles", text: "Auto_Mobiles" },
      ],

      activeItem: null,
      modal: null,
      tag: null,
      imgTag: null,

      index: null,
    };
  },

  mounted() {
    this.firebase();
  },

  methods: {
    firebase() {
      var data = firebase.database().ref("/quproducts");
      data.on("value", (snap) => {
        var obj = snap.val();
        this.products = obj;

        this.proKey = Object.keys(this.products);
        this.product.id = Object.keys(this.products).length + 1;

        // console.log(this.product.id);
      });

      // console.log(this.products);


      var data2 = firebase.database().ref("/newQuProducts");
      data2.on("value", (snap) => {
        var obj = snap.val();
        this.products2 = obj;

        this.proKey2 = Object.keys(this.products2);
        // this.product.id = Object.keys(this.products).length + 1;

        // console.log(this.product.id);
      });
    },

    uploadData() {
      // console.log()

      firebase.database().ref("/newQuProducts").push(this.product);

      // Toast.fire({
      //       type: 'success',
      //       title: 'Updated  successfully'
      //     })

      $("#product").modal("hide");
      this.reset();
    },

    addImg(){
      this.product.images.push(this.imgTag);
    },

    deleteImage(img) {
      let image = firebase.storage().refFromURL(img);

      // this.product.images;

      image
        .delete()
        .then(function () {
          console.log("image deleted");
        })
        .catch(function () {
          // Uh-oh, an error occurred!
          console.log("an error occurred");
        });
    },

    addTag() {
      this.product.tags.push(this.tag);
      this.tag = "";
    },
    addChoice() {
      this.product.chooser.push({
        tpe: this.choose.tpe,
        color: this.choose.color,
        mrp: this.choose.mrp,
        price: this.choose.price,
        coins: this.choose.coins,
        qty: this.choose.qty,
      });
      this.tag = "";
      this.choose.tpe = "";
      this.choose.color = "";
      this.choose.mrp = "";
      this.choose.price = "";
      this.choose.coins = "";
      this.choose.qty = "";
    },
    uploadImage(e) {
      if (e.target.files[0]) {
        let file = e.target.files[0];

        var storageRef = firebase
          .storage()
          .ref("products/" + Math.random() + "_" + file.name);

        let uploadTask = storageRef.put(file);

        uploadTask.on(
          "state_changed",
          {},
          () => {
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...

            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.product.images.push(downloadURL);
            });
          }
        );
      }
    },

    reset() {
      this.product = {
        title: null,
        description: null,
        price: null,
        tags: [],
        images: null,
        chooser: [],
        battery: null,
        brand: null,
        camera: null,
        cpu: null,
        display: null,
        id: Object.keys(this.products).length + 1,
        coins: null,
        mrp: null,
        qty: null,
        ram: null,
        rom: null,
        shopId: "Qu@Pro",
        size: null,
        uid: null,
        weight: null,
        category: null,
      };
    },

    addNew() {
      this.modal = "new";
      this.reset();
      $("#product").modal("show");
    },
    updateProduct() {
      firebase
        .database()
        .ref("/newQuProducts")
        .child(this.editKey)
        .update(this.product);
      // this.$firestore.products.doc(this.product.id).update(this.product);
      // Toast.fire({
      //   type: 'success',
      //   title: 'Updated  successfully'
      // })

      $("#product").modal("hide");
    },

    editProduct(product, proId) {
      this.modal = "edit";
      this.product = product;
      this.product.chooser = [],
      this.editKey = proId;
      $("#product").modal("show");
    },

    deleteProduct(doc) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          firebase.database().ref("/newQuProducts").child(doc).remove();
          this.$firestore.products.doc(doc.id).delete();

          Toast.fire({
            type: "success",
            title: "Deleted  successfully",
          });
        }
      });
    },

    // orderPage(x) {
    //   if (x == 2) {
    //     new Vue({
    //       VueRouter,

    //       render: (h) => h(order),
    //     }).$mount("#uploadPrd");
    //   } else if (x == 3) {
    //     new Vue({
    //       VueRouter,

    //       render: (h) => h(Carosl),
    //     }).$mount("#uploadPrd");
    //   }
    // },

    readData() {},
    addProduct() {
      this.$firestore.products.add(this.product);

      // Toast.fire({
      //   type: 'success',
      //   title: 'Product created successfully'
      // })

      $("#product").modal("hide");
    },
  },
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.img-wrapp {
  position: relative;
}
.img-wrapp span.delete-img {
  position: absolute;
  top: -14px;
  left: -2px;
}
.img-wrapp span.delete-img:hover {
  cursor: pointer;
}

.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
