<template>
  <div class="products" id="uploadPrd">
    <div class="container pt-3">
      <div class="h-100">
        <div class="row h-100 justify-content-left align-items-center">
          <div class="">
            <h3>Old Products Orders Page</h3>
            <!-- <div class="row">
              <button class="btn btn-primary col-lg-6"> Our Orders</button>
              <button class="btn btn-primary col-lg-6"> Shop Orders</button>
            </div> -->

            <p>QuPro Orders</p>
          </div>
        </div>
      </div>

      <hr />

      <div class="product-test">
        <h3 class="d-inline-block">Order list</h3>
        <!-- <button @click="addNew" class="btn btn-primary float-right">Add Product</button> -->

        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Customer</th>
                <th>Payment Id</th>
                <th>Time</th>
                <th>Product</th>
                <th>Quantity</th>
                <th>placement</th>
                <th>Operation</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="prOne in proKey" :key="prOne.id">
                <td
                  @click="
                    showInfo(
                      products[prOne].productId,
                      products[prOne].customer
                    )
                  "
                  style="color: blue;"
                >
                  {{ products[prOne].orderId }}
                </td>
                <td v-if="products[prOne].customer">
                  {{ products[prOne].customer }}
                </td>
                <td v-else>Customer Id</td>

                <td v-if="products[prOne].paymentId">
                  {{ products[prOne].paymentId }}
                </td>
                <td v-else>COD: ₹{{ products[prOne].due }}</td>

                <td v-if="products[prOne].timeToOrd">
                  {{ products[prOne].timeToOrd.substring(0, 19) }}
                </td>
                <td v-else>Time</td>

                <td>
                  {{ products[prOne].productId }}
                </td>

                <td>
                  {{ products[prOne].qty }}
                </td>
                <td v-if="products[prOne].placed">
                  <b style="color: blue">Placed</b>
                </td>
                <td v-else>
                  <b style="color: red">Not Placed</b>
                  <!-- <button class="btn btn-primary" ><i class="fa fa-edit"></i></button> -->
                  <!-- <button class="btn btn-danger ml-1" ><i class="fa fa-trash"></i></button> -->
                </td>
                <td>
                  <button class="btn btn-danger" @click="dltOrder(prOne)">
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="product"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editLabel">Product</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <!-- main product -->
              <div class="col-md-8">
                <div class="row">
                  <div class="form-group col-md-6">
                    <input
                      type="text"
                      placeholder="Product Name"
                      v-model="product.title"
                      class="form-control"
                    />
                  </div>
                  <!-- <div class="form-group col-md-6">
                    <b-form-select
                      class="form-control"
                      v-model="product.category"
                      :options="options"
                      id="PostCat"
                    >
                    </b-form-select>
                  </div> -->

                  <div class="form-group col-md-6">
                    <input
                      type="text"
                      placeholder="Product Camera"
                      v-model="product.price"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <input
                      type="text"
                      placeholder="Product Battery"
                      v-model="product.coins"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <input
                      type="text"
                      placeholder="shop Id"
                      v-model="product.shopId"
                      class="form-control"
                      :disabled="true"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <p @click="showProDetails(placementKey)">
                      {{ placementKey }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- product sidebar -->
              <div class="col-md-4">
                <h4 class="display-6">Customer Details</h4>
                <hr />

                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Name"
                    v-model="customer.name"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Phone"
                    v-model="customer.call"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Email"
                    v-model="customer.email"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="PINCODE"
                    v-model="customer.pin"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Address"
                    v-model="customer.address"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="form-group col-md-5">
              Delivered By:
              <b-form-select
                class="form-control"
                :options="options"
                id="PostCat"
              >
              </b-form-select>
            </div>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="waMsg(placementKey)"
            >
              Place Order
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { VueEditor } from "vue2-editor";
// import { rtDb} from '../../firebase';
import firebase from "firebase";
// import VueSwal from 'vue-swal'
import Swal from "sweetalert2";
// import VueGallery from 'vue-gallery';
import $ from "jquery";
window.Swal = Swal;
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

window.Toast = Toast;

export default {
  name: "Products",
  // components: {
  //   'gallery': VueGallery,
  // },
  props: {
    msg: String,
  },

  data() {
    return {
      products: [],
      proKey: [],
      placementKey: null,
      customer: {
        email: null,
        call: null,
        name: null,
        pin: null,
        address: null,
      },
      editKey: "",
      product: {
        title: null,
        description: null,
        price: null,
        tags: [],
        images: [],
        battery: null,
        brand: null,
        camera: null,
        cpu: null,
        coins: null,
        display: null,
        id: null,
        qty: null,
        ram: null,
        rom: null,
        shopId: "Qu@Pro",
        size: null,
        uid: null,
        weight: null,
        category: null,
      },
      options: [
        { value: "+919660663783", text: "Nishant Sharma" },
        { value: "+919660663783", text: "Umesh Sharma" },
        { value: "+919660663783", text: "Yash Sharma" },
        { value: "+919660663783", text: "Amit Yadav" },
      ],

      activeItem: null,
      modal: null,
      tag: null,
      index: null,
    };
  },

  mounted() {
    this.firebase();
  },

  methods: {
    firebase() {
      var data = firebase
        .database()
        .ref("shopData")
        .child("Qu@Pro")
        .child("Orders")
        .orderByChild("timeToOrd");
      data.on("value", (snap) => {
        var obj = snap.val();
        this.products = obj;
        console.log(this.products);

        this.proKey = Object.keys(this.products);
        // this.product.id = Object.keys(this.products).length + 1;

        // console.log(this.proKey);
      });

      // console.log(this.products);
    },

    showInfo(pId, cId) {
      this.prodModal(pId);
      this.userInfo(cId);
      this.placementKey = pId;
      this.modal = "edit";
      $("#product").modal("show");
    },

    userInfo(user) {
      // firebase.firestore().collection('users')
      var docRef = firebase.firestore().collection("users").doc(user);
      var getData;
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            getData = doc.data();
            // console.log("Document data:", doc.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
          console.log(getData);
          this.customer = getData;
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },

    prodModal(prId) {
      // var path = location.pathname;
      // var directories = path.split("/");
      // this.proId = directories[directories.length - 1];

      var booksData = firebase.database().ref("quproducts").child(prId);
      booksData.on("value", (snapshot) => {
        this.product = snapshot.val();
        console.log(this.product);
      });
    },

    showProDetails(placementKey) {
      window.open(
        "https://quprosolutions.com/quproducts/" + placementKey,
        "_blank"
      );
    },

    dltOrder(order) {
      // alert(order);
      firebase
        .database()
        .ref("shopData")
        .child("Qu@Pro")
        .child("Orders")
        .child(order)
        .remove()
        .then(alert("Deleted Successfully"));
    },

    placement() {},

    waMsg(key) {
      var num = document.getElementById("PostCat").value;
      location.href =
        "https://api.whatsapp.com/send?phone=" +
        num +
        "&text=" +
        "https://quprosolutions.com/quproducts/" +
        key;
    },

    // uploadData(){

    //   // console.log()

    //   firebase.database().ref('/quproducts').push(this.product);

    //   // Toast.fire({
    //   //       type: 'success',
    //   //       title: 'Updated  successfully'
    //   //     })

    //        $('#product').modal('hide');
    //        this.reset();
    // },

    // deleteImage(img,index){

    //   let image = firebase.storage().refFromURL(img);

    //   this.product.images.splice(index,1);

    //   image.delete().then(function() {
    //     console.log('image deleted');
    //   }).catch(function() {
    //     // Uh-oh, an error occurred!
    //     console.log('an error occurred');
    //   });

    // },

    // addTag(){
    //    this.product.tags.push(this.tag);
    //    this.tag = "";
    // },
    // uploadImage(e){

    //   if(e.target.files[0]){

    //       let file = e.target.files[0];

    //       var storageRef = firebase.storage().ref('products/'+ Math.random() + '_'  + file.name);

    //       let uploadTask  = storageRef.put(file);

    //       uploadTask.on('state_changed', {

    //       }, () => {
    //         // Handle unsuccessful uploads
    //       }, () => {
    //         // Handle successful uploads on complete
    //         // For instance, get the download URL: https://firebasestorage.googleapis.com/...

    //         uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
    //           this.product.images.push(downloadURL);
    //         });

    //       });

    //   }

    // },

    // reset(){
    //   this.product = {
    //       title:null,
    //       description:null,
    //       price:null,
    //       tags:[],
    //       images:[],
    //       battery:null,
    //       brand:null,
    //       camera:null,
    //       cpu:null,
    //       display:null,
    //       id: Object.keys(this.products).length + 1,
    //       qty:null,
    //       ram:null,
    //       rom:null,
    //       shopId: 'Qu@Pro',
    //       size:null,
    //       uid:null,
    //       weight:null,
    //       category:null

    //   }
    // },

    // updateProduct(){
    //   firebase.database().ref('/quproducts').child(this.editKey).update(this.product);
    //     // this.$firestore.products.doc(this.product.id).update(this.product);
    //       // Toast.fire({
    //       //   type: 'success',
    //       //   title: 'Updated  successfully'
    //       // })

    //        $('#product').modal('hide');
    // },

    // editProduct(product, proId){
    //   this.modal = 'edit';
    //   this.product = product;
    //   this.editKey = proId;
    //   $('#product').modal('show');
    // },

    // deleteProduct(doc){

    //   Swal.fire({
    //     title: 'Are you sure?',
    //     text: "You won't be able to revert this!",
    //     type: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Yes, delete it!'
    //   }).then((result) => {
    //     if (result.value) {
    //       firebase.database().ref('/quproducts').child(doc).remove();
    //       this.$firestore.products.doc(doc.id).delete()

    //       Toast.fire({
    //         type: 'success',
    //         title: 'Deleted  successfully'
    //       })

    //     }
    //   })

    // },
    // readData(){

    // },
    // addProduct(){

    //   this.$firestore.products.add(this.product);

    //       // Toast.fire({
    //       //   type: 'success',
    //       //   title: 'Product created successfully'
    //       // })

    //   $('#product').modal('hide');
    // }
  },
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.img-wrapp {
  position: relative;
}
.img-wrapp span.delete-img {
  position: absolute;
  top: -14px;
  left: -2px;
}
.img-wrapp span.delete-img:hover {
  cursor: pointer;
}

.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
