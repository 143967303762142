<template>
  <div class="overview mt-5 container" id="uploadPrd">
    <div class="row">
      <button class="btn btn-primary ml-5" @click="bannr(0)">National Banners</button>
      <button class="btn btn-secondary ml-5" @click="bannr(1)"> Market Banners</button>
    </div>
    <div id="national">
      <div class="container h-100 mt-5">
        <div class="intro h-100">
          <div class="row mt-5 h-100 justify-content-center align-items-center">
            <div class="col-md-6">
              <h3> National Carousel updation page</h3>
              <p>You can addd or delete anny carousel anytime from anywhere.</p>
            </div>
            <div class="col-md-6">
              <img src="/img/svg/overview.svg" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
        <div>
          <label for="product_image">Carousel Images</label>
          <input type="file" @change="uploadImage" class="form-control" />
        </div>
        <div>
          <div class="p-1">
            <div class="img-wrapp">
              <img :src="product.images" alt="" width="80px" />
              <span class="delete-img" @click="deleteImage(image, index)"
                >X</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="product-test">
        <h3 class="d-inline-block">Carousel list</h3>

        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>image</th>
                <th>Modify</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="product in carslData" :key="product.id">
                <td>
                  <img :src="product.image" width="300px" height="200px" />
                </td>

                <td>
                  <!-- <button class="btn btn-primary" @click="editProduct(product)">Edit</button> -->
                  <button
                    class="btn btn-danger"
                    @click="deleteProduct(product.id)"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div id="baazar" style="display: none;">
      <div class="container h-100 mt-5">
        <div class="intro h-100">
          <div class="row mt-5 h-100 justify-content-center align-items-center">
            <div class="col-md-6">
              <h3>Market Carousel updation page</h3>
              <p>You can addd or delete anny carousel anytime from anywhere.</p>
            </div>
            <div class="col-md-6">
              <img src="/img/svg/overview.svg" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
        <div>
          <label for="product_image">Carousel Images</label>
          <input type="file" @change="uploadMarketImage" class="form-control" />
        </div>
        <div>
          <div class="p-1">
            <div class="img-wrapp">
              <img :src="product.images" alt="" width="80px" />
              <span class="delete-img" @click="deleteMarketProduct(image, index)"
                >X</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="product-test">
        <h3 class="d-inline-block">Carousel list</h3>

        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>image</th>
                <th>Modify</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="product in marCarslData" :key="product.id">
                <td>
                  <img :src="product.image" width="300px" height="200px" />
                </td>

                <td>
                  <!-- <button class="btn btn-primary" @click="editProduct(product)">Edit</button> -->
                  <button
                    class="btn btn-danger"
                    @click="deleteMarketProduct(product.id)"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { fb, db, rtDb} from '../firebase';
import firebase from "firebase";
import Swal from "sweetalert2";
export default {
  name: "carosl",
  props: {
    msg: String,
  },
  data() {
    return {
      carslData: [],
      marCarslData: [],

      product: {
        images: null,
      },
      index: null,
    };
  },
  mounted() {
    this.carsl();
  },
  // mounted(){
  //   this.getMarker();
  // },
  methods: {
    async carsl() {
      const snapshot = await firebase.firestore().collection("carousel").get();
      snapshot.forEach((doc) => {
        this.carslData.push(doc.data());
      });

      const snaps = await firebase.firestore().collection("carouselShop").get();
      snaps.forEach((doc) => {
        this.marCarslData.push(doc.data());
      });
    },

    uploadImage(e) {
      if (e.target.files[0]) {
        let file = e.target.files[0];

        var storageRef = firebase
          .storage()
          .ref("carousel/" + Math.random() + "_" + file.name);

        let uploadTask = storageRef.put(file);

        uploadTask.on(
          "state_changed",
          () => {},
          () => {
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...

            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.product.images = downloadURL;
              // firestore.doc(document.getElementById('picId').value).add({
              //   'image': this.product.images,
              // })
              firebase.firestore().collection("carousel").add({
                image: this.product.images,
              });
            });
          }
        );
      }
    },
    uploadMarketImage(e) {
      if (e.target.files[0]) {
        let file = e.target.files[0];

        var storageRef = firebase
          .storage()
          .ref("carouselShop/" + Math.random() + "_" + file.name);

        let uploadTask = storageRef.put(file);

        uploadTask.on(
          "state_changed",
          () => {},
          () => {
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...

            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.product.images = downloadURL;
              // firestore.doc(document.getElementById('picId').value).add({
              //   'image': this.product.images,
              // })
              firebase.firestore().collection("carouselShop").add({
                image: this.product.images,
              });
            });
          }
        );
      }
    },
    deleteProduct(pro) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        // alert(pro);
        if (result.value) {
          firebase.firestore().collection("/carousel").doc(pro).delete();
          // this.$firestore.products.doc(doc.id).delete()
          alert("Deleted successfully");
          //   Toast.fire({
          //     type: 'success',
          //     title: 'Deleted  successfully'
          //   })
        }
      });
    },
    deleteMarketProduct(pro) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        // alert(pro);
        if (result.value) {
          firebase.firestore().collection("/carouselShop").doc(pro).delete();
          // this.$firestore.products.doc(doc.id).delete()
          alert("Deleted successfully");
          //   Toast.fire({
          //     type: 'success',
          //     title: 'Deleted  successfully'
          //   })
        }
      });
    },
    bannr(x){
      if(x == 0){
        document.getElementById('national').style.display = 'block';
        document.getElementById('baazar').style.display = 'none';
      }else if(x == 1){
        document.getElementById('national').style.display = 'none';
        document.getElementById('baazar').style.display = 'block';
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>