<template>
  <div class="products" id="uploadPrd">
    <div class="container pt-3">
      <div class="h-100">
        <div class="row h-100 justify-content-left align-items-center">
          <div class="">
            <!-- <h3>Shop Page</h3> -->
          </div>
        </div>
      </div>
      <hr />
      <h3>Add Products into shops directly</h3>
      <div class="row mb-3" style="border-style: ridge; padding: 30px">
        <div class="form-group col-md-6">
          <input
            type="text"
            placeholder="ShopId"
            v-model="newProd.shopId"
            class="form-control"
          />
        </div>
        <div class="form-group col-md-6">
          <input
            type="number"
            placeholder="Product ID"
            v-model="newProd.id"
            class="form-control"
          />
        </div>
        <div class="form-group col-md-6">
          <input
            type="text"
            placeholder="Product Title"
            v-model="newProd.title"
            class="form-control"
          />
        </div>

        <div class="form-group col-md-6">
          <input
            type="number"
            placeholder="Product Price"
            v-model="newProd.price"
            class="form-control"
          />
        </div>
        <div class="form-group col-md-6">
          <input
            type="text"
            placeholder="Image Url"
            v-model="newProd.images"
            class="form-control"
          />
        </div>
        <div class="form-group col-md-6">
          <input
            type="text"
            placeholder="Product Quantity"
            v-model="newProd.qty"
            class="form-control"
          />
        </div>
        <div class="form-group col-md-6">
          <!-- <input
            type="text"
            placeholder="Product Description"
            v-model="newProd.description"
            class="form-control"
          /> -->
          <textarea
            placeholder="Product Description"
            class="col-lg-12"
            rows="10"
            v-model="newProd.description"
          ></textarea>
        </div>
        <div class="form-group col-md-6">
          <button class="btn btn-success" @click="uplShopData()">
            Add Product
          </button>
        </div>
      </div>

      <hr />

      <div class="product-test">
        <h3 class="d-inline-block">Shop list</h3>

        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Shop</th>
                <th>Shop ID</th>
                <th>PINCODE</th>
                <th>Call</th>
                <th>Address</th>
                <th>Subscriber</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="prOne in shopData"
                :key="prOne.id"
                @click="firebase(prOne.shopId, prOne.shopName)"
              >
                <td>
                  {{ prOne.shopName }}
                </td>
                <td>
                  {{prOne.shopId}}
                </td>
                <td>
                  {{ prOne.pin }}
                </td>

                <td>
                  {{ prOne.phn }}
                </td>

                <td>
                  {{ prOne.addr }}
                </td>

                <td v-if="prOne.expDate">
                  <b style="color: green"
                    >Subscribed ( {{ prOne.expDate.substring(0, 10) }} )</b
                  >
                </td>
                <td v-else>
                  <b style="color: red">Not Subscribed</b>
                </td>

                <td v-if="prOne.state == 0">
                  <b style="color: blue">Open</b>
                </td>
                <td v-else>
                  <b style="color: red">Closed</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="container pt-3" id="ordrShw">
      <div class="h-100">
        <div class="row h-100 justify-content-left align-items-center">
          <div class="">
            <!-- <h3>Orders</h3> -->
            <!-- <div class="row">
              <button class="btn btn-primary col-lg-6"> Our Orders</button>
              <button class="btn btn-primary col-lg-6"> Shop Orders</button>
            </div> -->

            <!-- <p>Shop Orders</p> -->
          </div>
        </div>
      </div>

      <hr />

      <div class="product-test">
        <h3 class="d-inline-block">
          Products list of: <span style="color: Red"> {{ theShop }}</span>
        </h3>
        <!-- <button @click="addNew" class="btn btn-primary float-right">Add Product</button> -->

        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Price</th>

                <th>Modify</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="prOne in proKey" :key="prOne.id">
                <td>
                  {{ products[prOne].title }}
                </td>

                <td>
                  {{ products[prOne].price }}
                </td>

                <td>
                  <button
                    class="btn btn-primary"
                    @click="addNew(products[prOne])"
                  >
                    <i class="fa fa-edit"></i>
                  </button>
                  <!-- <button
                      class="btn btn-danger ml-1"
                      @click="deleteProduct(prOne)"
                    >
                      <i class="fa fa-trash"></i>
                    </button> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3>Verified Product List</h3>

        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Price</th>

                <th>Product Key</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="prOne in proKeyVerfd" :key="prOne.id">
                <td>
                  {{ productsVerfd[prOne].title }}
                </td>

                <td>
                  {{ productsVerfd[prOne].chooser[0].price }}
                </td>

                <td>
                  {{prOne}}
                </td>

                <!-- <td>
                  <button
                    class="btn btn-primary"
                    @click="addNew(products[prOne])"
                  >
                    <i class="fa fa-edit"></i>
                  </button>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>


      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="product"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editLabel">Edit Product</h5>
            <p>{{ thProd }}</p>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <!-- main product -->
              <div class="col-md-8">
                <div class="row">
                  <div class="form-group col-md-6">
                    <input
                      type="text"
                      placeholder="Product Name"
                      v-model="product.title"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <b-form-select
                      class="form-control"
                      v-model="product.category"
                      :options="options"
                      id="PostCat"
                    >
                    </b-form-select>
                  </div>

                  <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Camera"
                          v-model="product.camera"
                          class="form-control"
                        />
                      </div> -->

                  <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Battery"
                          v-model="product.battery"
                          class="form-control"
                        />
                      </div> -->

                  <div class="form-group col-md-6">
                    <input
                      type="text"
                      placeholder="Product Brand"
                      v-model="product.brand"
                      class="form-control"
                    />
                  </div>

                  <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Cpu"
                          v-model="product.cpu"
                          class="form-control"
                        />
                      </div> -->

                  <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Display"
                          v-model="product.display"
                          class="form-control"
                        />
                      </div> -->

                  <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          v-if="product.category == 'Mobiles'"
                          placeholder="Product Ram"
                          v-model="product.ram"
                          class="form-control"
                        />
                      </div> -->

                  <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Rom"
                          v-model="product.rom"
                          class="form-control"
                        />
                      </div> -->

                  <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Size"
                          v-model="product.size"
                          class="form-control"
                        />
                      </div> -->

                  <!-- <div class="form-group col-md-6">
                        <input
                          type="text"
                          placeholder="Product Weight"
                          v-model="product.weight"
                          class="form-control"
                        />
                      </div> -->

                  <div class="form-group col-md-6">
                    <input
                      type="number"
                      placeholder="product Id"
                      v-model="product.id"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <input
                      type="text"
                      placeholder="shop Id"
                      v-model="product.shopId"
                      class="form-control"
                    />
                  </div>

                  <!-- <div class="form-group col-md-6">
                    <input
                      type="number"
                      placeholder="Product price"
                      v-model="product.price"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <input
                      type="number"
                      placeholder="Product MRP"
                      v-model="product.mrp"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <input
                      type="number"
                      placeholder="Coins Applied"
                      v-model="product.coins"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <input
                      type="number"
                      placeholder="Product Quantity"
                      v-model="product.qty"
                      class="form-control"
                    />
                  </div> -->
                </div>

                <div class="form-group">
                  <!-- <vue-editor v-model="product.description"></vue-editor> -->
                  <textarea
                    placeholder="Product Description"
                    class="col-lg-12"
                    rows="10"
                    v-model="product.description"
                  ></textarea>
                </div>
              </div>
              <!-- product sidebar -->
              <div class="col-md-4">
                <h4 class="display-6">Product Details</h4>
                <hr />

                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Type"
                    v-model="choose.tpe"
                    class="form-control"
                  />
                  <input
                    type="text"
                    placeholder="Color"
                    v-model="choose.color"
                    class="form-control"
                  />
                  <input
                    type="number"
                    placeholder="Type MRP"
                    v-model="choose.mrp"
                    class="form-control"
                  />
                  <input
                    type="number"
                    placeholder="Type Price"
                    v-model="choose.price"
                    class="form-control"
                  />
                  <input
                    type="number"
                    placeholder="Type Coins"
                    v-model="choose.coins"
                    class="form-control"
                  />
                  <input
                    type="number"
                    placeholder="Quantity"
                    v-model="choose.qty"
                    class="form-control"
                  />

                  <button class="btn btn-block btn-primary" @click="addChoice">
                    Add Choice
                  </button>

                  <div>
                    <p v-for="itm in product.chooser" :key="itm._id">
                      <span class="p-1">
                        {{ itm }}
                      </span>
                    </p>
                  </div>
                </div>

                <hr />

                <div class="form-group">
                  <input
                    type="text"
                    @keyup.188="addTag"
                    placeholder="Product tags"
                    v-model="tag"
                    class="form-control"
                  />

                  <div class="d-flex">
                    <p v-for="tag in product.tags" :key="tag._id">
                      <span class="p-1">{{ tag }}</span>
                    </p>
                  </div>
                </div>

                <div class="form-group">
                  <label for="product_image">Product Images</label>
                  <input
                    type="text"
                    placeholder="Image Url"
                    v-model="product.images"
                    class="form-control"
                  />
                </div>

                <div class="form-group d-flex">
                  <div
                    class="p-1"
                    v-for="(image, index) in product.images"
                    :key="index"
                  >
                    <div class="img-wrapp">
                      <img :src="image" alt="" width="80px" />
                      <span
                        class="delete-img"
                        @click="deleteImage(image, index)"
                        >X</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div>
                  <gallery :images="images" :index="index" @close="index = null"></gallery>
                      <div
                        class="image"
                        v-for="(image, imageIndex) in images"
                        :key="imageIndex"
                        @click="index = imageIndex"
                        :style="{ backgroundImage: 'url(' + image + ')', width: '300px', height: '200px' }"
                      ></div>
                </div> -->
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              @click="uploadData()"
              type="button"
              class="btn btn-primary"
              v-if="modal == 'new'"
            >
              Save changes
            </button>
            <button
              @click="updateProduct()"
              type="button"
              class="btn btn-primary"
              v-if="modal == 'edit'"
            >
              Apply changes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { VueEditor } from "vue2-editor";
// import { rtDb} from '../../firebase';
import firebase from "firebase";
// import VueSwal from 'vue-swal'
import Swal from "sweetalert2";
// import VueGallery from 'vue-gallery';
import $ from "jquery";
window.Swal = Swal;
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

window.Toast = Toast;

export default {
  name: "Products",
  // components: {
  //   'gallery': VueGallery,
  // },
  props: {
    msg: String,
  },

  data() {
    return {
      products: [],
      proKey: [],
      proKeyVerfd: [],
      thProd: null,

      shopData: [],
      shpId: null,
      theShop: null,
      placementKey: null,
      customer: {
        email: null,
        call: null,
        name: null,
        pin: null,
        address: null,
      },
      editKey: "",
      newProd: {
        id: null,
        title: null,
        description: null,
        price: null,
        images: null,
        shopId: null,
        qty: null,
      },
      product: {
        title: null,
        description: null,
        price: null,
        chooser: [],
        tags: [],
        images: [],
        battery: null,
        brand: null,
        camera: null,
        cpu: null,
        display: null,
        id: null,
        qty: null,
        ram: null,
        rom: null,
        shopId: null,
        size: null,
        uid: null,
        weight: null,
        category: null,
      },
      choose: {
        tpe: null,
        color: null,
        mrp: null,
        price: null,
        coins: null,
        qty: null,
      },
      options: [
        { value: "Grocery", text: "Grocery" },
        { value: "Latest", text: "Latest" },
        { value: "Trending", text: "Trending" },
        { value: "Clothes", text: "Clothes" },
        { value: "Mobiles", text: "Mobiles" },
        { value: "Electronics", text: "Electronics" },
        { value: "Shoes", text: "Shoes" },
        { value: "Jewellery", text: "Jewellery" },
        { value: "Vegetables", text: "Vegetables" },
        { value: "Cosmetics", text: "Cosmetics" },
        { value: "Books", text: "Books" },
        { value: "Utencils", text: "Utencils" },
        { value: "Gifts", text: "Gifts" },
        { value: "Bag_Bottles", text: "Bag_Bottles" },
        { value: "Kitchen", text: "Kitchen" },
        { value: "Home_Utilities", text: "Home_Utilities" },
        { value: "Sports_Equipments", text: "Sports_Equipments" },
        { value: "Construction_Material", text: "Construction_Material" },
        { value: "Furniture", text: "Furniture" },
        { value: "Food", text: "Food" },
        { value: "Auto_Mobiles", text: "Auto_Mobiles" },
      ],

      activeItem: null,
      modal: null,
      tag: null,

      index: null,
    };
  },

  mounted() {
    this.shopInfo();
    // this.firebase();
  },

  methods: {
    firebase(shpId, tShop) {
      this.theShop = tShop;
      // await this.reset();
      var data = firebase.database().ref("Products").child(shpId);
      // console.log("data  " + data);

      if (data != undefined && data != null && data != "" && data != "null") {
        data.on("value", (snap) => {
          var obj = snap.val();
          if (obj != undefined && obj != null && obj != "" && obj != "null") {
            this.products = obj;
            console.log("Show Products" + this.products);

            this.proKey = Object.keys(this.products);
          } else {
            this.products = [];
            this.proKey = [];
          }

          // this.product.id = Object.keys(this.products).length + 1;

          // console.log(this.proKey);
        });

        // this.reset();
      } else {
        this.products = [];
        this.proKey = [];
      }

      window.scrollTo(0, document.body.scrollHeight);
      this.verfdProds(shpId);

      // console.log(this.products);
    },

    verfdProds(shpId){
      var data = firebase.database().ref("shoProducts").child(shpId);
      // console.log("data  " + data);

      if (data != undefined && data != null && data != "" && data != "null") {
        data.on("value", (snap) => {
          var obj = snap.val();
          if (obj != undefined && obj != null && obj != "" && obj != "null") {
            this.productsVerfd = obj;
            console.log("Show Products" + this.productsVerfd);

            this.proKeyVerfd = Object.keys(this.productsVerfd);
          } else {
            this.productsVerfd = [];
            this.proKeyVerfd = [];
          }

          // this.product.id = Object.keys(this.products).length + 1;

          // console.log(this.proKey);
        });

        // this.reset();
      } else {
        this.productsVerfd = [];
        this.proKeyVerfd = [];
      }
    },

    async shopInfo() {
      const snapshot = await firebase
        .firestore()
        .collection("shop")
        .orderBy("shopName")
        .get();
      snapshot.forEach((doc) => {
        this.shopData.push(doc.data());
      });
    },

    uplShopData() {
      firebase
        .database()
        .ref("/Products")
        .child(this.newProd.shopId)
        .push(this.newProd)
        .then(
          Swal.fire({
            title: "Uploaded Successfully",
            text: "",
            type: "success",
            showCancelButton: true,
          })
        );
    },

    uploadData() {
      // console.log()

      firebase
        .database()
        .ref("/shoProducts")
        .child(this.product.shopId)
        .push(this.product);

      // Toast.fire({
      //       type: 'success',
      //       title: 'Updated  successfully'
      //     })

      $("#product").modal("hide");
      this.reset();
    },

    // editProduct(product, proId) {
    //   this.modal = "edit";
    //   this.product = product;
    //   this.editKey = proId;
    //   $("#product").modal("show");
    // },

    deleteImage(img, index) {
      let image = firebase.storage().refFromURL(img);

      this.product.images.splice(index, 1);

      image
        .delete()
        .then(function () {
          console.log("image deleted");
        })
        .catch(function () {
          // Uh-oh, an error occurred!
          console.log("an error occurred");
        });
    },

    addTag() {
      this.product.tags.push(this.tag);
      this.tag = "";
    },
    addChoice() {
      this.product.chooser.push({
        tpe: this.choose.tpe,
        color: this.choose.color,
        mrp: this.choose.mrp,
        price: this.choose.price,
        coins: this.choose.coins,
        qty: this.choose.qty,
      });
      this.choose.tpe = "";
      this.choose.color = "";
      this.choose.mrp = "";
      this.choose.price = "";
      this.choose.coins = "";
      this.choose.qty = "";
    },
    uploadImage(e) {
      if (e.target.files[0]) {
        let file = e.target.files[0];

        var storageRef = firebase
          .storage()
          .ref("products/" + Math.random() + "_" + file.name);

        let uploadTask = storageRef.put(file);

        uploadTask.on(
          "state_changed",
          {},
          () => {
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...

            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.product.images.push(downloadURL);
            });
          }
        );
      }
    },

    reset() {
      this.product = {
        title: null,
        description: null,
        price: null,
        tags: [],
        images: null,
        chooser: [],
        battery: null,
        brand: null,
        camera: null,
        cpu: null,
        display: null,
        id: Object.keys(this.products).length + 1,
        coins: null,
        mrp: null,
        qty: null,
        ram: null,
        rom: null,
        shopId: null,
        size: null,
        uid: null,
        weight: null,
        category: null,
      };
    },

    addNew(prod) {
      this.thProd = prod;
      this.modal = "new";
      this.reset();
      $("#product").modal("show");
    },

    // goToOrders(idOfShop) {
    //   this.shpId = idOfShop;
    //   this.firebase(idOfShop);
    //   this.getProds(idOfShop);
    //   document.getElementById("ordrShw").style.display = "block";
    // },

    // getProds(shId) {
    //   var data = firebase.database().ref("/Products").child(shId);
    //   data.on("value", (snap) => {
    //     var obj = snap.val();
    //     this.productsTwo = obj;

    //     this.proKeys = Object.keys(this.productsTwo);
    //     this.productsTwo.id = Object.keys(this.productsTwo).length + 1;

    //     // console.log(this.product.id);
    //   });

    // console.log(this.products);
  },

  // showInfo(pId, cId) {
  //   this.prodModal(pId);
  //   this.userInfo(cId);
  //   this.placementKey = pId;
  //   this.modal = "edit";
  //   $("#product").modal("show");
  // },

  // userInfo(user) {
  //   // firebase.firestore().collection('users')
  //   var docRef = firebase.firestore().collection("users").doc(user);
  //   var getData;
  //   docRef
  //     .get()
  //     .then((doc) => {
  //       if (doc.exists) {
  //         getData = doc.data();
  //         // console.log("Document data:", doc.data());
  //       } else {
  //         // doc.data() will be undefined in this case
  //         console.log("No such document!");
  //       }
  //       console.log(getData);
  //       this.customer = getData;
  //     })
  //     .catch(function (error) {
  //       console.log("Error getting document:", error);
  //     });
  // },

  // prodModal(prId) {
  //   // var path = location.pathname;
  //   // var directories = path.split("/");
  //   // this.proId = directories[directories.length - 1];

  //   var booksData = firebase
  //     .database()
  //     .ref("Products")
  //     .child(this.shpId)
  //     .child(prId);
  //   booksData.on("value", (snapshot) => {
  //     this.product = snapshot.val();
  //     console.log(this.product);
  //   });
  // },

  // placement() {},
  // waMsg(key) {
  //   var num = document.getElementById("PostCat").value;
  //   location.href =
  //     "https://api.whatsapp.com/send?phone=" +
  //     num +
  //     "&text=" +
  //     "https://quprosolutions.com/quproducts/" +
  //     key;
  // },

  // uploadData(){

  //   // console.log()

  //   firebase.database().ref('/quproducts').push(this.product);

  //   // Toast.fire({
  //   //       type: 'success',
  //   //       title: 'Updated  successfully'
  //   //     })

  //        $('#product').modal('hide');
  //        this.reset();
  // },

  // deleteImage(img,index){

  //   let image = firebase.storage().refFromURL(img);

  //   this.product.images.splice(index,1);

  //   image.delete().then(function() {
  //     console.log('image deleted');
  //   }).catch(function() {
  //     // Uh-oh, an error occurred!
  //     console.log('an error occurred');
  //   });

  // },

  // addTag(){
  //    this.product.tags.push(this.tag);
  //    this.tag = "";
  // },
  // uploadImage(e){

  //   if(e.target.files[0]){

  //       let file = e.target.files[0];

  //       var storageRef = firebase.storage().ref('products/'+ Math.random() + '_'  + file.name);

  //       let uploadTask  = storageRef.put(file);

  //       uploadTask.on('state_changed', {

  //       }, () => {
  //         // Handle unsuccessful uploads
  //       }, () => {
  //         // Handle successful uploads on complete
  //         // For instance, get the download URL: https://firebasestorage.googleapis.com/...

  //         uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
  //           this.product.images.push(downloadURL);
  //         });

  //       });

  //   }

  // },

  // reset(){
  //   this.product = {
  //       title:null,
  //       description:null,
  //       price:null,
  //       tags:[],
  //       images:[],
  //       battery:null,
  //       brand:null,
  //       camera:null,
  //       cpu:null,
  //       display:null,
  //       id: Object.keys(this.products).length + 1,
  //       qty:null,
  //       ram:null,
  //       rom:null,
  //       shopId: 'Qu@Pro',
  //       size:null,
  //       uid:null,
  //       weight:null,
  //       category:null

  //   }
  // },

  // updateProduct(){
  //   firebase.database().ref('/quproducts').child(this.editKey).update(this.product);
  //     // this.$firestore.products.doc(this.product.id).update(this.product);
  //       // Toast.fire({
  //       //   type: 'success',
  //       //   title: 'Updated  successfully'
  //       // })

  //        $('#product').modal('hide');
  // },

  // editProduct(product, proId){
  //   this.modal = 'edit';
  //   this.product = product;
  //   this.editKey = proId;
  //   $('#product').modal('show');
  // },

  // deleteProduct(doc){

  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: "You won't be able to revert this!",
  //     type: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, delete it!'
  //   }).then((result) => {
  //     if (result.value) {
  //       firebase.database().ref('/quproducts').child(doc).remove();
  //       this.$firestore.products.doc(doc.id).delete()

  //       Toast.fire({
  //         type: 'success',
  //         title: 'Deleted  successfully'
  //       })

  //     }
  //   })

  // },
  // readData(){

  // },
  // addProduct(){

  //   this.$firestore.products.add(this.product);

  //       // Toast.fire({
  //       //   type: 'success',
  //       //   title: 'Product created successfully'
  //       // })

  //   $('#product').modal('hide');
  // }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.img-wrapp {
  position: relative;
}
.img-wrapp span.delete-img {
  position: absolute;
  top: -14px;
  left: -2px;
}
.img-wrapp span.delete-img:hover {
  cursor: pointer;
}

.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
