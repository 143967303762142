import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import '../custom.scss'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './firebase'
import { VueEditor } from "vue2-editor";
import VueGallery from 'vue-gallery';
import jQuery from 'jquery';

Vue.config.devtools = true;
import store from './store';
import {Plugin} from 'vue-fragment'
import VueSwal from 'vue-swal'
import Swal from 'sweetalert2';
import 'popper.js';
import 'bootstrap';
import JwPagination from 'jw-vue-pagination';
Vue.component('jw-pagination', JwPagination);


window.Swal = Swal;
window.$ = window.jQuery = jQuery;

Vue.use(Plugin);
Vue.use(VueGallery);
Vue.use(VueEditor);
Vue.use(VueSwal)
Vue.use(BootstrapVue);



Vue.use(VueRouter);
//filters
import {brandFilter} from "./filters/brandFilter";
import {formatMoney} from "./filters/priceFormatter";
import {paginationPipe} from "./filters/paginationFilter";


import Home from './Pages/Home';
import Profile from './components/UserProfile.vue';
import ShoppingCartPage from './Pages/ShoppingCartPage';
import seeAll from './components/productPages/seeAllProd.vue';
import allProds from './components/productPages/allProducts.vue';
import ProductDetailPage from './components/products/ProductDetail.vue';
import ShoProductDetailPage from './components/products/shoProDetail.vue';
import NotFound from './Pages/error.vue';
import Suppliers from './Pages/suppliers.vue';

// import Mobiles from './Pages/mobiles.vue';
import ContactUs from './Pages/contact.vue';
import AboutUs from './components/core/about.vue';
import search from './components/core/search.vue';
import Paginate from './components/core/cardPaginate.vue';
import Policies from './components/core/policies.vue';
import {shortenTitle} from "./filters/shortenTitle";
import {CLEAR_BRAND_FILTER, CLEAR_ORDER_BY_PRICE} from "./store/types";

Vue.config.productionTip = false;

Vue.filter('brandFilter', brandFilter);
Vue.filter('shortenTitle', shortenTitle)
Vue.filter('priceFormatter', formatMoney);
Vue.filter('pagination', paginationPipe);

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
  });
  
  window.Toast = Toast;


const routes = [
    {path: '/', redirect: '/products'},
    {path: '/parked', redirect: '/products'},
    {path: '/products', component: Home,},
    {path: '/quproducts/:id', component: ProductDetailPage},
    {path: '/sProducts/:cat/:id', component: ShoProductDetailPage},
    {path: '/products/:cat', component: allProds},
    {path: '/cart', component: ShoppingCartPage},
    {path: '/contact', component: ContactUs},
    {path: '/suppliers', component: Suppliers},
    {path: '/seeAll/:cat', component: seeAll},
    {path: '/search/:cat', component: search},
    {path: '/profile', component: Profile},
    {path: '/paginate', component: Paginate},
    {path: '/aboutUs', component: AboutUs},
    {path: '/policies', component: Policies},
    {path: '/parked', redirect: '/products'},
    { path: '*', component: NotFound }
];

// export const bus = new Vue();
const router = new VueRouter({
                        routes,
                        mode:'history'        });
new Vue({
    render: h => h(App),
    store,
    router
}).$mount('#app');

router.afterEach(() => {
    window.scrollTo(0,0);
    store.commit(CLEAR_BRAND_FILTER);
    store.commit(CLEAR_ORDER_BY_PRICE);
});
export default router