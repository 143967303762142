<template>
    <div class="mt-5" id="niQup">
    <section id="about" class="about pt-5">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2 style="color:green;">About Us</h2>
        </div>

        <div class="row content">
          <div class="col-lg-6">
            <p>
                QuPro vision is to create India's most reliable & frictionless commerce eco-system
                that creates life changing experiences for buyers and sellers.
            </p>
            <p>
                QuPro Application is quick, user friendly & enables shoppers buy products with a breeze.
            </p>
            <p>
                It give timely notifications on your mail & contact number so that you don't miss amazing deals and offers.
            </p>

            <h4 class="pt-3" style="color: blue;">
                Affordable Shopping at your finger tips
            </h4>
            <ul>
                <li>
                    QuPro is one of the unique online shopping sites in India where shopping is accessible to all.
                </li>
                <li>
                    You are sure to be impressed with our reasonable discounts.
                </li>
            </ul>
            <hr>
            <h4 class="pb-5" style="color: #964f45;">
                We provide platform for every local market to national level.
            </h4>
            
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0">
              <h4 style="color: blue;">
                Online Shopping made easy at  QuPro
            </h4>
            <ul>
              <li>QuPro is the ultimate destination for shopping.</li>
              <li>It's time to redefine your style statement with our treasure prove of trending items.</li>
              <li>Our online store brings you the latest  in designed products straight out of manufacturing houses.</li>
              <li>Shop online on QuPro from the comfort of your home & get your favourites delivered right to your doorsteps.</li>              
            </ul>

            <img src="@/assets/shakeHand.png" class="img-fluid animated" alt="" >
            <!-- <a href="#" class="btn-learn-more">Learn More</a> -->
          </div>
        </div>

      </div>
    </section>
    
    </div>
</template>
<script>
export default {
    name: 'about',
    
}
</script>

<style scoped>

.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-left: 28px;
  position: relative;
}

.about .content ul li + li {
  margin-top: 10px;
}

.about .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #47b2e4;
  line-height: 1;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .content .btn-learn-more {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 4px;
  transition: 0.3s;
  line-height: 1;
  color: #47b2e4;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  margin-top: 6px;
  border: 2px solid #47b2e4;
}

.about .content .btn-learn-more:hover {
  background: #47b2e4;
  color: #fff;
  text-decoration: none;
}

#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us .content {
  padding: 60px 100px 0 100px;
}

.why-us .content h3 {
  font-weight: 400;
  font-size: 34px;
  color: #313233;
}

.why-us .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}

.why-us .content p {
  font-size: 15px;
  color: #848484;
}

.why-us .img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.why-us .accordion-list {
  padding: 0 100px 60px 100px;
}

.why-us .accordion-list ul {
  padding: 0;
  list-style: none;
}

.why-us .accordion-list li + li {
  margin-top: 15px;
}

.why-us .accordion-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

.why-us .accordion-list a {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-right: 30px;
  outline: none;
}

.why-us .accordion-list span {
  color: #47b2e4;
  font-weight: 600;
  font-size: 18px;
  padding-right: 10px;
}

.why-us .accordion-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.why-us .accordion-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.why-us .accordion-list .icon-show {
  display: none;
}

.why-us .accordion-list a.collapsed {
  color: #343a40;
}

.why-us .accordion-list a.collapsed:hover {
  color: #47b2e4;
}

.why-us .accordion-list a.collapsed .icon-show {
  display: inline-block;
}

.why-us .accordion-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1024px) {
  .why-us .content, .why-us .accordion-list {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .why-us .img {
    min-height: 400px;
  }
  .why-us .content {
    padding-top: 30px;
  }
  .why-us .accordion-list {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .why-us .img {
    min-height: 200px;
  }
}

</style>