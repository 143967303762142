<template>
    <ShoppingCartContainer></ShoppingCartContainer>
</template>

<script>
    import ShoppingCartContainer from "../components/shopping-cart/ShoppingCartContainer";
    export default {
        name: "ShoppingCartPage",
        components: {ShoppingCartContainer}
    }
</script>

<style scoped>

</style>
