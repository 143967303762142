<template>
  <div class="products" id="uploadPrd">
    <div class="container pt-3">
      <div class="h-100">
        <div class="row h-100 justify-content-left align-items-center">
          <div class="">
            <!-- <h3>Shop Page</h3> -->
          </div>
        </div>
      </div>

      <hr />

      <div class="product-test">
        <h3 class="d-inline-block">Shop list</h3>
        <div class="row mb-5">
          <input
            type="text"
            placeholder="Enter PINCODE"
            v-model="srchPin"
            class="form-control col-4"
          />
          <button class="btn btn-primary" @click="shopInfo(srchPin)">
            Search
          </button>
        </div>

        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Shop</th>
                <th>PINCODE</th>
                <th>Call</th>
                <th>Address</th>
                <th>Subscriber</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="prOne in shopData"
                :key="prOne.id"
                @click="goToOrders(prOne.shopId)"
              >
                <td>
                  {{ prOne.shopName }}
                </td>
                <td>
                  {{ prOne.pin }}
                </td>

                <td>
                  {{ prOne.phn }}
                </td>

                <td>
                  {{ prOne.addr }}
                </td>

                <td v-if="prOne.expDate">
                  <b style="color: green"
                    >Subscribed ( {{ prOne.expDate.substring(0, 10) }} )</b
                  >
                </td>
                <td v-else>
                  <b style="color: red">Not Subscribed</b>
                </td>

                <td v-if="prOne.state == 0">
                  <b style="color: blue">Open</b>
                </td>
                <td v-else>
                  <b style="color: red">Closed</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="container pt-3" id="ordrShw">
      <div class="h-100">
        <div class="row h-100 justify-content-left align-items-center">
          <div class="">
            <!-- <h3>Orders</h3> -->
            <!-- <div class="row">
              <button class="btn btn-primary col-lg-6"> Our Orders</button>
              <button class="btn btn-primary col-lg-6"> Shop Orders</button>
            </div> -->

            <!-- <p>Shop Orders</p> -->
          </div>
        </div>
      </div>

      <hr />

      <div class="product-test" id="shopOrders">
        <h3 class="d-inline-block">Order list</h3>
        <!-- <button @click="addNew" class="btn btn-primary float-right">Add Product</button> -->

        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Customer</th>
                <th>Payment Id</th>
                <th>Time</th>
                <th>Product</th>
                <th>Quantity</th>
                <th>placement</th>
                <th>Operation</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="prOne in proKey" :key="prOne.id">
                <td
                  @click="
                    showInfo(
                      products[prOne].productId,
                      products[prOne].customer,
                      products[prOne].orderId,
                      products[prOne].selector
                    )
                  "
                  style="color: blue"
                >
                  {{ products[prOne].orderId }}
                </td>
                <td v-if="products[prOne].customer">
                  {{ products[prOne].customer }}
                </td>
                <td v-else>Customer Id</td>

                <td v-if="products[prOne].paymentId">
                  {{ products[prOne].paymentId }}
                </td>
                <td v-else>COD: ₹{{ products[prOne].due }}</td>

                <td v-if="products[prOne].timeToOrd">
                  {{ products[prOne].timeToOrd.substring(0, 19) }}
                </td>
                <td v-else>Time</td>
                <td>
                  {{ products[prOne].productId }}
                </td>

                <td>
                  {{ products[prOne].qty }}
                </td>
                <td v-if="products[prOne].placed">
                  <b style="color: blue">Placed</b>
                </td>
                <td v-else>
                  <b style="color: red">Not Placed</b>
                  <!-- <button class="btn btn-primary" ><i class="fa fa-edit"></i></button> -->
                  <!-- <button class="btn btn-danger ml-1" ><i class="fa fa-trash"></i></button> -->
                </td>
                <td>
                  <button class="btn btn-danger" @click="dltOrder(prOne)">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <hr />

      <div class="product-test">
        <div class="row">
          <h3 class="d-inline-block col-md-6">All Shop Order list</h3>
          <!-- <div class="text-right col-md-6">
            <button class="btn btn-secondary ml-5 mb-3" @click="sortTable()">
              Sort
            </button>
          </div> -->
        </div>

        <!-- <button @click="addNew" class="btn btn-primary float-right">Add Product</button> -->

        <div class="table-responsive">
          <table
            class="table"
            id="myTable"
            v-for="ordIndex in proKeyAll"
            :key="ordIndex.id"
          >
            <thead>
              <tr>
                <th>Shop Id</th>
                <th>Order Id</th>
                <th>Customer</th>
                <th>Payment Id</th>
                <th>Time</th>
                <th>Product</th>
                <th>Qty</th>
                <th>placement</th>
                <th>Operation</th>
              </tr>
            </thead>

            <tbody
              v-for="prOne in Object.keys(productsAll[ordIndex])"
              :key="prOne.id"
            >
              <tr
                v-for="prKey in Object.keys(productsAll[ordIndex][prOne])"
                :key="prKey.id"
              >
                <td style="color: green">
                  {{ prOne }}
                </td>
                <td>
                  <!-- {{ Object.keys(productsAll[ordIndex])[0] }} -->
                  <button
                    class="btn btn-outline-primary"
                    @click="
                      showInfo2(
                        productsAll[ordIndex][prOne][prKey].productId,
                        productsAll[ordIndex][prOne][prKey].customer,
                        productsAll[ordIndex][prOne][prKey].orderId,
                        prOne,
                        productsAll[ordIndex][prOne][prKey].selector
                      )
                    "
                  >
                    {{ productsAll[ordIndex][prOne][prKey].orderId }}
                  </button>
                </td>
                <td v-if="productsAll[ordIndex][prOne][prKey].customer">
                  {{ productsAll[ordIndex][prOne][prKey].customer }}
                </td>
                <td v-else>Customer Id</td>

                <td v-if="productsAll[ordIndex][prOne][prKey].paymentId">
                  {{ productsAll[ordIndex][prOne][prKey].paymentId }}
                </td>
                <td v-else>
                  COD: ₹{{ productsAll[ordIndex][prOne][prKey].due }}
                </td>

                <td v-if="productsAll[ordIndex][prOne][prKey].timeToOrd">
                  {{
                    productsAll[ordIndex][prOne][prKey].timeToOrd.substring(
                      0,
                      19
                    )
                  }}
                </td>
                <td v-else>Time</td>
                <td>
                  {{ productsAll[ordIndex][prOne][prKey].productId }}
                </td>

                <td>
                  {{ productsAll[ordIndex][prOne][prKey].qty }}
                </td>
                <td v-if="productsAll[ordIndex][prOne][prKey].placed">
                  <b style="color: blue">Placed</b>
                </td>
                <td v-else>
                  <b style="color: red">Not Placed</b>
                  <!-- <button class="btn btn-primary" ><i class="fa fa-edit"></i></button> -->
                  <!-- <button class="btn btn-danger ml-1" ><i class="fa fa-trash"></i></button> -->
                </td>
                <td>
                  <button
                    class="btn btn-danger"
                    @click="dltOrder2(prKey, prOne)"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <hr />

      <!-- <div class="product-test">
        <h3 class="d-inline-block">Products list</h3>

        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Price</th>

                <th>Modify</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="prTwo in proKeys" :key="prTwo.id">
                <td>
                  {{ productsTwo[prTwo].title }}
                </td>

                <td>
                  {{ productsTwo[prTwo].price }}
                </td>

                <td>
                  <button class="btn btn-primary">
                    <i class="fa fa-edit"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> -->
    </div>
    <div
      class="modal fade"
      id="product"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editLabel">Order</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <!-- main product -->
              <div class="col-md-4">
                <h4 class="display-6">Product</h4>
                <hr />
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="shop Id"
                    v-model="product.category"
                    class="form-control"
                    :disabled="true"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Product Name"
                    v-model="product.title"
                    class="form-control"
                    :disabled="true"
                  />
                </div>
                <!-- <div class="form-group col-md-6">
                    <b-form-select
                      class="form-control"
                      v-model="product.category"
                      :options="options"
                      id="PostCat"
                    >
                    </b-form-select>
                  </div> -->

                <!-- <div class="form-group">
                    <p
                    >
                    {{product.chooser[0].price}}
                    </p>
                  </div> -->

                <!-- <div class="form-group col-md-6">
                    <input
                      type="text"
                      placeholder="Coins Applied"
                      v-model="product.coins"
                      class="form-control"
                    />
                  </div> -->

                <!-- <div class="form-group col-md-6">
                    <p>
                      {{ customer }}
                    </p>
                  </div> -->

                <div class="form-group">
                  <p>
                    {{ product.chooser[selector] }}
                  </p>
                </div>
                <div class="form-group">
                  <img class="col-md-12" :src="product.images" alt="image" />
                </div>
                <!-- <div class="form-group">
                    <p @click="showProDetails(product.shopId, placementKey)">
                      {{ placementKey }}
                    </p>
                  </div> -->
              </div>
              <div class="col-md-4">
                <h4 class="display-6">Shop Details</h4>
                <hr />

                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Name"
                    v-model="product.shopId"
                    class="form-control"
                    :disabled="true"
                  />
                </div>
                <div id="extPar" style="display: none">
                  <p>Shop Name: {{ currentShopInfo.shopName }}</p>
                  <p>Address: {{ currentShopInfo.addr }}</p>
                  <p>Call: {{ currentShopInfo.phn }}</p>
                  <!-- <p>
                    {{currentShopInfo}}
                  </p> -->
                </div>
                <div id="extBtn">
                  <button
                    class="btn btn-success btn-block"
                    @click="extractShop(product.shopId)"
                  >
                    Extract Info
                  </button>
                </div>
              </div>
              <!-- product sidebar -->
              <div class="col-md-4">
                <h4 class="display-6">Customer Details</h4>
                <hr />

                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Name"
                    v-model="customer.name"
                    class="form-control"
                    :disabled="true"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Phone"
                    v-model="customer.call"
                    class="form-control"
                    :disabled="true"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Email"
                    v-model="customer.email"
                    class="form-control"
                    :disabled="true"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="PINCODE"
                    v-model="customer.pin"
                    class="form-control"
                    :disabled="true"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Address"
                    v-model="customer.address"
                    class="form-control"
                    :disabled="true"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="form-group col-md-5">
              Delivered By:
              <b-form-select
                class="form-control"
                :options="options"
                id="PostCat"
              >
              </b-form-select>
            </div>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="waMsg(placementKey)"
            >
              <!-- @click="waMsg(placementKey)" -->
              Place Order
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { VueEditor } from "vue2-editor";
// import { rtDb} from '../../firebase';
import firebase from "firebase";
// import VueSwal from 'vue-swal'
import Swal from "sweetalert2";
// import VueGallery from 'vue-gallery';
import $ from "jquery";
window.Swal = Swal;
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

window.Toast = Toast;

export default {
  name: "Products",
  // components: {
  //   'gallery': VueGallery,
  // },
  props: {
    msg: String,
  },

  data() {
    return {
      products: [],
      proKey: [],

      productsTwo: [],
      proKeys: [],

      productsAll: [],
      proKeyAll: [],

      srchPin: null,
      theOrdr: null,

      currentShopInfo: [],

      selector: 0,

      shopData: [],
      shpId: null,
      placementKey: null,
      customer: {
        email: null,
        call: null,
        name: null,
        pin: null,
        address: null,
      },

      editKey: "",
      product: {
        title: null,
        description: null,
        price: null,
        tags: [],
        images: [],
        chooser: [],
        battery: null,
        brand: null,
        camera: null,
        cpu: null,
        coins: null,
        display: null,
        id: null,
        qty: null,
        ram: null,
        rom: null,
        shopId: "Qu@Pro",
        size: null,
        uid: null,
        weight: null,
        category: null,
      },
      options: [
        { value: "+919660663783", text: "+919660663783" },
        { value: "+919660663783", text: "+919660663783" },
        { value: "+919660663783", text: "+919660663783" },
        { value: "+919660663783", text: "+919660663783" },
      ],

      activeItem: null,
      modal: null,
      tag: null,
      index: null,
    };
  },

  mounted() {
    this.getAllOrds();
    //   // this.firebase();
  },

  methods: {
    firebase(shpId) {
      var data = firebase
        .database()
        .ref("shopData")
        .child(shpId)
        .child("Orders")
        .orderByChild("timeToOrd");
      data.on("value", (snap) => {
        var obj = snap.val();
        this.products = obj;
        console.log(this.products);

        this.proKey = Object.keys(this.products);
        // this.product.id = Object.keys(this.products).length + 1;

        // console.log(this.proKey);
      });

      // console.log(this.products);
    },

    async getAllOrds() {
      var ordrs = [];
      // var ordrKeys = [];

      var data = await firebase.database().ref("shopData");
      await data.on("value", (snap) => {
        var obj = snap.val();
        var shpKeys = Object.keys(obj);

        // this.products = obj;
        // console.log(shpKeys);

        for (var ky in shpKeys) {
          // console.log(obj[shpKeys[ky]]['Orders']);
          // console.log(shpKeys[ky]);
          var thKey = shpKeys[ky];
          ordrs.push({ [thKey]: obj[shpKeys[ky]]["Orders"] });
          // ordrKeys.push(Object.keys(this.productsAll));
        }

        // this.proKey = Object.keys(this.products);
        // this.product.id = Object.keys(this.products).length + 1;

        // console.log(this.proKey);
      });
      console.log(ordrs);

      this.productsAll = ordrs;
      this.proKeyAll = Object.keys(this.productsAll);
      console.log(this.productsAll);
      // console.log(this.proKeyAll);

      // console.log(this.products);
    },

    async shopInfo(srchPin) {
      this.shopData = [];
      const snapshot = await firebase
        .firestore()
        .collection("shop")
        .where("pin", "==", srchPin)
        .orderBy("shopName")
        .get();
      snapshot.forEach((doc) => {
        this.shopData.push(doc.data());
      });
    },

    goToOrders(idOfShop) {
      this.shpId = idOfShop;
      this.firebase(idOfShop);
      this.getProds(idOfShop);
      document.getElementById("ordrShw").style.display = "block";
      // window.scrollTo(0, document.body.scrollHeight);
      document.getElementById("shopOrders").scrollIntoView();
    },

    showProDetails(shpId, placementKey) {
      window.open(
        "https://quprosolutions.com/sProducts/" + shpId + "/" + placementKey,
        "_blank"
      );
    },

    getProds(shId) {
      var data = firebase.database().ref("/Products").child(shId);
      data.on("value", (snap) => {
        var obj = snap.val();
        this.productsTwo = obj;

        this.proKeys = Object.keys(this.productsTwo);
        this.productsTwo.id = Object.keys(this.productsTwo).length + 1;

        // console.log(this.product.id);
      });

      // console.log(this.products);
    },

    // print() {
    //   const modal = document.getElementById("product");
    //   const cloned = modal.cloneNode(true);
    //   let section = document.getElementById("print");

    //   if (!section) {
    //     section = document.createElement("div");
    //     section.id = "print";
    //     document.body.appendChild(section);
    //   }

    //   section.innerHTML = "";
    //   section.appendChild(cloned);
    //   window.print();
    // },

    showInfo(pId, cId, ordrId, slctr) {
      (this.currentShopInfo = []),
      this.selector = slctr;
        (document.getElementById("extBtn").style.display = "block");
      document.getElementById("extPar").style.display = "none";
      this.theOrdr = ordrId;
      this.prodModal(pId);
      this.userInfo(cId);
      this.placementKey = pId;
      this.modal = "edit";
      $("#product").modal("show");
    },

    userInfo(user) {
      // firebase.firestore().collection('users')
      var docRef = firebase.firestore().collection("users").doc(user);
      var getData;
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            getData = doc.data();
            // console.log("Document data:", doc.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
          // console.log(getData);
          this.customer = getData;
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },

    prodModal(prId) {
      // var path = location.pathname;
      // var directories = path.split("/");
      // this.proId = directories[directories.length - 1];

      var booksData = firebase
        .database()
        .ref("shoProducts")
        .child(this.shpId)
        .child(prId);
      booksData.on("value", (snapshot) => {
        this.product = snapshot.val();
        // console.log(this.product);
      });
    },

    showInfo2(pId, cId, ordrId, shopId, slctr) {
      (this.currentShopInfo = []),
      this.selector = slctr ?? 0;
        (document.getElementById("extBtn").style.display = "block");
      document.getElementById("extPar").style.display = "none";
      this.theOrdr = ordrId;
      this.prodModal2(pId, shopId);
      this.userInfo(cId);
      this.placementKey = pId;
      this.modal = "edit";
      $("#product").modal("show");
    },

    prodModal2(prId, shopId) {
      // var path = location.pathname;
      // var directories = path.split("/");
      // this.proId = directories[directories.length - 1];

      var parent = "shoProducts";
      var booksData;
      if (shopId == "Qu@Pro") {
        parent = "newQuProducts";
        booksData = firebase.database().ref(parent).child(prId);
      } else {
        booksData = firebase.database().ref(parent).child(shopId).child(prId);
      }

      booksData.on("value", (snapshot) => {
        this.product = snapshot.val();
        // console.log(this.product);
      });
    },

    async extractShop(shopId) {
      if (
        this.shopData == null ||
        this.shopData == [] ||
        this.shopData == undefined ||
        this.shopData == ""
      ) {
        this.shopData = [];
        const snapshot = await firebase
          .firestore()
          .collection("shop")
          .orderBy("shopName")
          .get();
        snapshot.forEach((doc) => {
          this.shopData.push(doc.data());
        });
      }
      for (var sh in this.shopData) {
        if (this.shopData[sh].shopId == shopId) {
          this.currentShopInfo = this.shopData[sh];
        }
      }
      document.getElementById("extBtn").style.display = "none";
      document.getElementById("extPar").style.display = "block";
      // console.log(this.shopData);
      // console.log(shopId);
    },

    placement() {
      firebase
        .database()
        .ref("/shopData")
        .child(this.product.shopId)
        .child("Placements")
        .push()
        .set({
          orderId: this.theOrdr,
          paid: this.product.price,
        });
    },
    waMsg() {
      // console.log(
      //   "Order Id: " +
      //     this.theOrdr +
      //     "\nProduct: " +
      //     this.product.title +
      //     "\nShop: " +
      //     this.product.brand +
      //     "\nDescription: " +
      //     this.product.description +
      //     "\nDelivered To *********" +
      //     "\nName: " +
      //     this.customer.name +
      //     "\nPhone No.: " +
      //     this.customer.call +
      //     "\nAddress: " +
      //     this.customer.address
      // );
      // this.placement();
      var num = document.getElementById("PostCat").value;
      location.href =
        "https://api.whatsapp.com/send?phone=" +
        num +
        "&text=" +
        "Order Id: " +
        this.theOrdr +
        " | Product: " +
        this.product.title +
        " | Shop: " +
        this.product.brand +
        " | Description: " +
        this.product.description +
        " | Delivered To *********" +
        " | Name: " +
        this.customer.name +
        " | Phone No.: " +
        this.customer.call +
        " | Address: " +
        this.customer.address;
      // "https://quprosolutions.com/quproducts/" +
      // key;
    },

    dltOrder(order) {
      // alert(order);
      firebase
        .database()
        .ref("shopData")
        .child(this.shpId)
        .child("Orders")
        .child(order)
        .remove()
        .then(alert("Deleted Successfully"))
        .catch((err) => {
          alert(err);
        });
    },
    dltOrder2(order, shopId) {
      // alert(order);
      firebase
        .database()
        .ref("shopData")
        .child(shopId)
        .child("Orders")
        .child(order)
        .remove()
        .then(alert("Deleted Successfully"))
        .catch((err) => {
          alert(err);
        });
    },

    sortTable() {
      var table, rows, switching, i, x, y, shouldSwitch;
      table = document.getElementById("myTable");
      switching = true;
      /*Make a loop that will continue until
  no switching has been done:*/
      while (switching) {
        //start by saying: no switching is done:
        switching = false;
        rows = table.rows;
        /*Loop through all table rows (except the
    first, which contains table headers):*/
        for (i = 1; i < rows.length - 1; i++) {
          //start by saying there should be no switching:
          shouldSwitch = false;
          /*Get the two elements you want to compare,
      one from current row and one from the next:*/
          x = rows[i].getElementsByTagName("TD")[3];
          y = rows[i + 1].getElementsByTagName("TD")[3];
          //check if the two rows should switch place:
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            //if so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
        if (shouldSwitch) {
          /*If a switch has been marked, make the switch
      and mark that a switch has been done:*/
          rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
          switching = true;
        }
      }
    },

    // uploadData(){

    //   // console.log()

    //   firebase.database().ref('/quproducts').push(this.product);

    //   // Toast.fire({
    //   //       type: 'success',
    //   //       title: 'Updated  successfully'
    //   //     })

    //        $('#product').modal('hide');
    //        this.reset();
    // },

    // deleteImage(img,index){

    //   let image = firebase.storage().refFromURL(img);

    //   this.product.images.splice(index,1);

    //   image.delete().then(function() {
    //     console.log('image deleted');
    //   }).catch(function() {
    //     // Uh-oh, an error occurred!
    //     console.log('an error occurred');
    //   });

    // },

    // addTag(){
    //    this.product.tags.push(this.tag);
    //    this.tag = "";
    // },
    // uploadImage(e){

    //   if(e.target.files[0]){

    //       let file = e.target.files[0];

    //       var storageRef = firebase.storage().ref('products/'+ Math.random() + '_'  + file.name);

    //       let uploadTask  = storageRef.put(file);

    //       uploadTask.on('state_changed', {

    //       }, () => {
    //         // Handle unsuccessful uploads
    //       }, () => {
    //         // Handle successful uploads on complete
    //         // For instance, get the download URL: https://firebasestorage.googleapis.com/...

    //         uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
    //           this.product.images.push(downloadURL);
    //         });

    //       });

    //   }

    // },

    // reset(){
    //   this.product = {
    //       title:null,
    //       description:null,
    //       price:null,
    //       tags:[],
    //       images:[],
    //       battery:null,
    //       brand:null,
    //       camera:null,
    //       cpu:null,
    //       display:null,
    //       id: Object.keys(this.products).length + 1,
    //       qty:null,
    //       ram:null,
    //       rom:null,
    //       shopId: 'Qu@Pro',
    //       size:null,
    //       uid:null,
    //       weight:null,
    //       category:null

    //   }
    // },

    // updateProduct(){
    //   firebase.database().ref('/quproducts').child(this.editKey).update(this.product);
    //     // this.$firestore.products.doc(this.product.id).update(this.product);
    //       // Toast.fire({
    //       //   type: 'success',
    //       //   title: 'Updated  successfully'
    //       // })

    //        $('#product').modal('hide');
    // },

    // editProduct(product, proId){
    //   this.modal = 'edit';
    //   this.product = product;
    //   this.editKey = proId;
    //   $('#product').modal('show');
    // },

    // deleteProduct(doc){

    //   Swal.fire({
    //     title: 'Are you sure?',
    //     text: "You won't be able to revert this!",
    //     type: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Yes, delete it!'
    //   }).then((result) => {
    //     if (result.value) {
    //       firebase.database().ref('/quproducts').child(doc).remove();
    //       this.$firestore.products.doc(doc.id).delete()

    //       Toast.fire({
    //         type: 'success',
    //         title: 'Deleted  successfully'
    //       })

    //     }
    //   })

    // },
    // readData(){

    // },
    // addProduct(){

    //   this.$firestore.products.add(this.product);

    //       // Toast.fire({
    //       //   type: 'success',
    //       //   title: 'Product created successfully'
    //       // })

    //   $('#product').modal('hide');
    // }
  },
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.img-wrapp {
  position: relative;
}
.img-wrapp span.delete-img {
  position: absolute;
  top: -14px;
  left: -2px;
}
.img-wrapp span.delete-img:hover {
  cursor: pointer;
}

.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
