import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

// firebase init
const firebaseConfig = {
    apiKey: "AIzaSyA0N4G_aRleryhlMjw3jYrWGHlLSh6Ge1k",
    authDomain: "qupro-solutions.firebaseapp.com",
    databaseURL: "https://qupro-solutions.firebaseio.com",
    projectId: "qupro-solutions",
    storageBucket: "qupro-solutions.appspot.com",
    messagingSenderId: "512861830335",
    appId: "1:512861830335:web:f58ff2269cb5a6ddbb1a0a",
    measurementId: "G-WTJKPK9D9T"
}
// firebase.initializeApp(firebaseConfig)
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

// utils
const db = firebase.firestore()
const auth = firebase.auth()

// collection references
const usersCollection = db.collection('users')
// const postsCollection = db.collection('posts')
// const commentsCollection = db.collection('comments')
// const likesCollection = db.collection('likes')

// export utils/refs
export {
  db,
  auth,
  usersCollection,
  // postsCollection,
  // commentsCollection,
  // likesCollection
}
